import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Item } from "../../../../@types/order";
import { RHFTextField } from "../../../../components/hook-form";
import Iconify from "../../../../components/Iconify";
import { INVENTORY_PATH } from "../../../../constants/paths";
import useProduct from "../../../../hooks/useProduct";
import useResetKey from "../../../../hooks/useResetKey";
import { setAlert } from "../../../../redux/slices/orders";
import axios from "../../../../utils/axios";
import { Errors } from "../../SnackAlert";

export const SectionProduct = () => {
  const form = useFormContext();
  const { key, resetK } = useResetKey();
  const [noData, setNotData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productOptions, setproductOptions] = useState<Item[]>([]);

  const items = form.watch("items");
  const company = form.watch("company");

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "items",
  });

  const { calculateTotal } = useProduct({ form });

  useEffect(() => {
    if (company?.name) {
      addProduct();
    }
  }, [company]);

  const addProduct = async () => {
    try {
      const response = await axios.get<Item[]>(`${INVENTORY_PATH}/products`, {
        params: {
          company_identification: company.identification,
        },
      });
      if (response.data.length === 0) {
        setNotData(true);
      }
      setproductOptions(response.data);
    } catch (e) {
      setAlert(Errors.E100);
    } finally {
      setLoading(false);
    }
  };

  const handleOnclose = () => {
    // setproductOptions([]);
    setNotData(false);
    resetK();
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: Item) => `${option.sku}${option.description}`,
  });

  return (
    <Accordion>
      <AccordionSummary expandIcon={<Iconify icon="akar-icons:chevron-down" />}>
        <Typography variant="subtitle1">Detalle de productos</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={2}>
          <Autocomplete
            key={key}
            onClose={handleOnclose}
            filterOptions={filterOptions}
            options={productOptions}
            loading={loading}
            loadingText="Buscando..."
            noOptionsText={
              noData ? "Sin resultados" : "Buscar por sku,upc o nombre producto"
            }
            getOptionLabel={(option) => `${option.sku} - ${option.description}`}
            onChange={(_, product) => {
              if (product) {
                const { quantity, external_sku, ...rest } = product;
                append({ ...rest, sku: external_sku });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar producto"
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    addProduct();
                    setLoading(true);
                    e.preventDefault();
                  }
                }}
                disabled={loading}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />

          <TableContainer sx={{ minWidth: 800, position: "relative" }}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell width={100}>Producto</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>UPC</TableCell>
                  <TableCell align="center">Cantidad</TableCell>
                  <TableCell>Bodega</TableCell>
                  <TableCell align="center">Valor</TableCell>
                  <TableCell align="center">Subtotal</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((f, i: number) => (
                  <TableRow key={f.id}>
                    <TableCell sx={{ width: "50%" }}>
                      {f["description"]}
                    </TableCell>
                    <TableCell sx={{ width: "50%", whiteSpace: "nowrap" }}>
                      {f["sku"]}
                    </TableCell>
                    <TableCell>{f["upc"]}</TableCell>
                    <TableCell align="center" sx={{ width: 0 }}>
                      <RHFTextField
                        name={`items.${i}.quantity`}
                        type="number"
                      />
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      Bodega quito
                    </TableCell>
                    <TableCell align="center" sx={{ width: 0 }}>
                      <RHFTextField
                        fullWidth
                        name={`items.${i}.price`}
                        sx={{ width: 130 }}
                        type="number"
                      />
                    </TableCell>
                    <TableCell align="center" sx={{ width: 0 }}>
                      <Typography variant="subtitle2">
                        {calculateTotal(items[i])}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => remove(i)}>
                        <Iconify
                          icon={"clarity:trash-line"}
                          width={20}
                          height={20}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
