import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Comment } from "../../../../@types/order";
import Iconify from "../../../../components/Iconify";
import moment from "moment";
import Label from "../../../../components/Label";

export const SectionInternalNotes = () => {
  const { watch, setValue } = useFormContext();
  const comments: Comment[] = watch("comments") ?? [];
  const [comment, setComment] = useState("");

  const handleAddComment = () => {
    const date = moment().format("YYYY-MM-DD hh:mm:ss");
    setValue("comments", [...comments, { comment, date }]);
    setComment("");
  };
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" color="primary">
            Notas internas
          </Typography>
        }
      />
      <CardContent>
        <Stack>
          <Stack minHeight={150} marginBottom={1}>
            {comments.length === 0 && (
              <Typography variant="subtitle1" textAlign="center">
                No se han agregado notas internas
              </Typography>
            )}
            {comments.map((c) => (
              <Stack direction="row" alignItems="center" gap={2}>
                <Label
                  variant={"filled"}
                  color="primary"
                  sx={{ textTransform: "capitalize" }}
                >
                  <Typography variant="caption">{c.date}</Typography>
                </Label>

                <Typography>{c.comment}</Typography>
              </Stack>
            ))}
          </Stack>
          <TextField
            value={comment}
            label="Ingresa notas referentes a la orden"
            onChange={(e) => setComment(e.target.value)}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                handleAddComment();
                e.preventDefault();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton color="primary" onClick={handleAddComment}>
                    <Iconify icon="fluent:send-16-filled" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
