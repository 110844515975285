import { useTheme } from "@mui/material/styles";
import {
  Checkbox,
  TableRow,
  TableCell,
  Typography,
  Box,
  Stack,
  IconButton,
  Button,
  styled,
  TableRowProps,
} from "@mui/material";
import Label from "../../../components/Label";
import Iconify from "../../../components/Iconify";
import { Order } from "../../../@types/order";
import ItemCell from "./ItemCell";
import {
  openDialogComment,
  openDialogDetail,
} from "../../../redux/slices/orders";
import { useDispatch } from "../../../redux/store";
import Moment from "react-moment";
import CourrierInfo from "../dialog/components/CourrierIinfo";
import React from "react";
import { StatusesEnum, StatusesMap } from "../../../enums/StatusesEnum";

type Props = {
  row: Order;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
};

export const OrderType = {
  1: "T1 - Despacho de Fulfillment Center de Bou",
  2: "T2 - Almacenaje temporal de paquetes sin empaque",
  3: "T3 - Recogida de otra Bodega o Fulfillment center",
};

export const PaymentMethod = {
  1: "Efectivo",
  2: "Transferencia",
  3: "Tarjeta",
};

const TableRowStyled = styled((props: TableRowProps) => (
  <TableRow
    {...props}
    sx={{
      "&:hover>td": {
        backgroundColor: "rgb(234 245 255); !important",
      },
      "&.Mui-selected>td": {
        backgroundColor: "#ccdded",
      },
    }}
  />
))();

export default function UserTableRow({ row, selected, onSelectRow }: Props) {
  const theme = useTheme();

  const dispatch = useDispatch();

  const { uuid, company, client, items, order_information } = row;

  const handleOpenDialogDetail = () => {
    dispatch(openDialogDetail(row));
  };

  const handleOpenDialogComment = () => {
    dispatch(openDialogComment(row));
  };

  return (
    <TableRowStyled hover={true} selected={selected}>
      <TableCell
        className="sticky"
        padding="checkbox"
        sx={{
          position: "sticky",
          left: 0,
          backgroundColor: "white",
          zIndex: 2,
        }}
      >
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell></TableCell>

      <TableCell>
        <Stack justifyContent="center">
          <Typography variant="body2" noWrap>
            <Moment unix format="DD/MM/YYYY">
              {row.order_information?.date}
            </Moment>
          </Typography>
          <Typography variant="caption" noWrap align="center">
            <Moment unix format="HH:mm:ss">
              {row.order_information?.date}
            </Moment>
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>
        <Box width={220} display="flex" justifyContent="center">
          <Typography variant="body2" noWrap>
            {company?.name}
          </Typography>
        </Box>
      </TableCell>

      <TableCell>
        <Box width={120} display="flex" justifyContent="center">
          <Typography variant="body2" noWrap>
            {client.client_address_information.country}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Stack justifyContent="center">
          <Button variant="text" color="info" onClick={handleOpenDialogDetail}>
            {uuid}
          </Button>
          <Typography variant="caption" align="center">
            {row.external_code}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>
        <Box width={250} textAlign="center">
          <Typography variant="body2" noWrap>
            {client.fullname}
          </Typography>
        </Box>
      </TableCell>

      <TableCell>
        <ItemCell items={items} />
      </TableCell>
      <TableCell>
        <Box width={175}>
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="button">Peso </Typography>
            {items
              ? items.reduce((p, c) => p + c.weight, 0).toFixed(2)
              : 0}kg <Iconify icon="mdi:weight" />
          </Box>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="button">Dimensiones</Typography>
            <Box display="flex" gap={1} alignItems="center">
              {items ? items.reduce((p, c) => p + (c.volume ?? 0), 0) : 0}m3{" "}
              <Iconify icon="bi:box-fill" />
            </Box>
          </Stack>
        </Box>
      </TableCell>
      <TableCell>
        <Box width={175} display="flex" flexDirection="column">
          <Typography variant="body2">Normal</Typography>
          <Typography variant="caption">
            {OrderType[order_information?.type_order ?? 1]}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <CourrierInfo order={row} />
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap>
          <Box width={125} display="flex" flexDirection="column">
            {row.hub?.name}
          </Box>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap>
          {PaymentMethod[row.payment_information?.payment_method ?? 1]}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2" noWrap>
          {row.payment_information?.unit}
          {row.payment_information.payment_method === "1"
            ? row.order_information?.cod
            : row.payment_information.amount}
          {}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Iconify
          width={27}
          height={27}
          icon={
            row.payment_information?.payment_method === "1"
              ? row.payment_information?.status === StatusesEnum.PENDING
                ? "bx:radio-circle"
                : "bx:radio-circle-marked"
              : "bx:radio-circle-marked"
          }
        />
      </TableCell>

      <TableCell>
        <IconButton onClick={handleOpenDialogComment}>
          <Iconify icon="bxs:comment-dots" />
        </IconButton>
      </TableCell>
      <TableCell align="center">
        <Stack width={180} direction="row" justifyContent="space-around">
          <IconMarked
            validate={["INVOICED", "PAID"]}
            value={row.services_pay?.company_payment}
          />
          <IconMarked
            validate={["PAID"]}
            value={row.services_pay?.company_payment}
          />
        </Stack>
      </TableCell>
      <TableCell align="center">
        <Stack width={180} direction="row" justifyContent="space-around">
          <IconMarked
            validate={["INVOICED", "PAID"]}
            value={row.services_pay?.courrier_payment}
          />
          <IconMarked
            validate={["PAID"]}
            value={row.services_pay?.courrier_payment}
          />
        </Stack>
      </TableCell>
      <TableCell
        align="left"
        sx={{
          position: "sticky",
          right: 0,
          backgroundColor: "white",
        }}
      >
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={(uuid === "banned" && "error") || "success"}
          sx={{
            textTransform: "uppercase",
            backgroundColor: validateStatus(row.status),
            color: "white",
          }}
        >
          {StatusesMap[`${row.status}`] ?? row.status}
        </Label>
      </TableCell>
    </TableRowStyled>
  );
}

const validateStatus = (status: string) => {
  switch (status) {
    case "CREATED":
      return "gray";
    case "PENDING":
      return "yellow";
    case "PICKING":
      return "#c57ddf";
    case "PACKING":
      return "#89589a";
    case "CANCELED":
      return "#d1b57b";
    case "SHIPPED":
      return "#a9d17b";
    case "READYTOSHIP":
      return "#4e3358";
    case "DELETED":
      return "#e7566f";
    case "INWAREHOUSE":
      return "#369032";
    case "RECOLECTADO":
      return "#94ed7d";
    case "INTRANSIT":
      return "#186715";
    case "INDELIVERYAREA":
      return "#035800";
    case "DELIVERED":
      return "#1fff44";
    case "TORETURN":
      return "#b53e74";
    case "RETURNED":
      return "red";
    default:
      return "blue";
  }
};
const IconMarked = (props: { value: string; validate: string[] }) => {
  const { value, validate } = props;
  return (
    <Iconify
      width={27}
      height={27}
      icon={
        validate.includes(value) ? "bx:radio-circle-marked" : "bx:radio-circle"
      }
    />
  );
};
