import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore";
import moment from "moment";
import {
  closeLoading,
  generateInvoice,
  generateLabel,
  setAlert,
} from "../redux/slices/orders";
import { useDispatch } from "../redux/store";
import { IPropsFirebaseCustomHook } from "./useImportFile";
import { saveAs } from "file-saver";

export enum TypeDocuments {
  INVOICE = "invoice",
  LABEL = "label",
}
export enum FirebaseDocumentStatusEnum {
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  SPLITTED = "SPLITTED",
}

const action = (snapshot: Unsubscribe) => {
  closeLoading();
  snapshot();
};

export default function useRequireInvoices({
  firestore,
}: IPropsFirebaseCustomHook) {

  const dispatch = useDispatch();

  const handleRequireGenerateDocuments = async (uuids: string[], typesDocuments: TypeDocuments, collectionName: string) => {
    const collectionDocuments = collection(firestore, collectionName);
    const document = await addDoc(collectionDocuments, {
      createdAt: moment().format(),
    });
    switch (typesDocuments) {
      case TypeDocuments.INVOICE:
        await dispatch(generateInvoice(uuids, `${document.id}`));
        break;
      case TypeDocuments.LABEL:
        await dispatch(generateLabel(uuids, `${document.id}`));
        break;
      default:
        break;
    }
    const snapshot = onSnapshot(
      doc(firestore, collectionName, document.id),
      (d) => {
        const status = d.data()?.status;

        switch (status) {
          case FirebaseDocumentStatusEnum.SUCCESS:
            saveAs(d.data()?.download_url);
            action(snapshot);
            break;
          case FirebaseDocumentStatusEnum.SPLITTED:
            saveAs(d.data()?.download_url);
            break;
          case FirebaseDocumentStatusEnum.FAILED:
            const message = d.data()?.error_message;
            setAlert({ open: true, type: "error", message });
            action(snapshot);
          default:
        }
      }
    );
  };

  return { handleRequireGenerateInvoice: handleRequireGenerateDocuments };
}