import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Divider,
  Grid,
  MenuItem,
  Stack,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { DialogType, Order } from "../../../@types/order";
import Iconify from "../../../components/Iconify";
import Page from "../../../components/Page";
import useTabs from "../../../hooks/useTabs";
import { StoreState } from "../../../redux/rootReducer";
import {
  closeDialogDetail,
  closeLoading,
  openLoading,
  setAlert,
} from "../../../redux/slices/orders";
import { useDispatch } from "../../../redux/store";
import TableItem from "./TableItem";
import Moment from "react-moment";
import { OrderType, PaymentMethod } from "../list/UserTableRow";
import Label from "../../../components/Label";
import { SectionLinesService } from "./SectionLineService";
import { SectionDocuments } from "./SectionDocuments";
import { SectionComments } from "./SectionComments";
import { useForm } from "react-hook-form";
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "../../../components/hook-form";
import axios from "../../../utils/axios";
import {
  ClientInformation,
  ContactInformation,
} from "./new-order/SectionClient";
import { useMutation, useQuery } from "@tanstack/react-query";
import { COVERAGE_PATH, ORDER_PATH } from "../../../constants/paths";
import { Errors } from "../SnackAlert";
import { StatusesEnum } from "../../../enums/StatusesEnum";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number | string;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "white",
  },
});

interface StyledTabProps {
  label: string;
  icon: string | React.ReactElement;
  value?: any;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-selected": {
    color: "#fff",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

export default function DialogDetail() {
  const { open, data } = useSelector<StoreState, DialogType<Order | undefined>>(
    (state) => state.orders.dialogOrderDetail
  );
  const { currentTab, onChangeTab } = useTabs("Resumen");

  const DETAIL_ORDER_TABS = [
    {
      value: "Resumen",
      icon: <Iconify icon={"ic:round-account-box"} width={20} height={20} />,
      component: <DetailOrder order={data ?? ({} as Order)} />,
    },
    //todo: NO REMOVER HASTA QUE SE CONTRUYA
    // {
    // 	value: "Historia",
    // 	icon: <Iconify icon={"ic:round-account-box"} width={20} height={20} />,
    // 	component: <HistoryOrder />,
    // },
  ];

  const theme = useTheme();

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeDialogDetail());
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <Page sx={{ backgroundColor: "#ebeaea4a" }}>
        <Box sx={{ backgroundColor: theme.palette.secondary.light, p: 2 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h5">
              Número de la orden {data?.uuid}
            </Typography>
            <Label
              variant={"filled"}
              color={"success"}
              sx={{ textTransform: "capitalize" }}
            >
              {data?.status}
            </Label>
          </Stack>
          <Typography variant="caption" color="white">
            Pedido creado{" "}
            <Moment unix format="LLLL" locale="es-US">
              {data?.order_information?.date}
            </Moment>
          </Typography>
          <Divider />
          <StyledTabs value={currentTab} onChange={onChangeTab}>
            {DETAIL_ORDER_TABS.map((tab) => (
              <StyledTab
                key={tab.value}
                label={tab.value}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </StyledTabs>
        </Box>
        {DETAIL_ORDER_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Page>
    </Dialog>
  );
}

export function DetailOrder(props: { order: Order }) {
  const { order } = props;

  const { data } = useQuery({
    queryKey: ["courriers"],
    queryFn: async () => {
      const response = await axios.get(`${COVERAGE_PATH}/courriers`);
      return response.data;
    },
  });

  const editCourrierMutation = useMutation({
    mutationFn: (order: Order) => {     
      return axios.patch(`${ORDER_PATH}/order-courrier`, {
        uuids: [order.uuid],
        courrier_info: order.courrier_info,
      });
    },
    onMutate: () => openLoading(),
    onSuccess: () => {
      closeLoading();
      setAlert(Errors.E009);
    },
    onError: () => {
      setAlert(Errors.E010);
      closeLoading();
    },
  });

  const requireInvoice = () =>
    order?.line_services?.some((line) => line.sku === "148");

  const methods = useForm<any>({
    shouldUnregister: false,
    defaultValues: { hasInvoice: requireInvoice(), ...order },
  });

  const canEditCourrier = () => {
    const can = [StatusesEnum.CREATED, StatusesEnum.PENDING].includes(
      order.status as StatusesEnum
    );
    return can;
  };

  return (
    <Stack spacing={2} padding={{ xs: 1, md: 2 }}>
      <TableItem items={order?.items ?? []} />
      <FormProvider methods={methods}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
          <Grid container marginRight={0} spacing={1} width={{ md: "60%" }}>
            <Grid item xs={12} sm={6} md={6}>
              <ClientInformation editable={false} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <ContactInformation editable={false} />
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" color="primary" mb={2}>
                    Datos de la orden
                  </Typography>
                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Stack width={320}>
                      <Typography>Tipo de envío</Typography>
                      <TextField value="Normal" />
                      <TextField
                        value={
                          OrderType[order?.order_information?.type_order ?? 1]
                        }
                      />
                    </Stack>
                    <Stack width={200}>
                      <Typography variant="body2">Courrier</Typography>
                      {canEditCourrier() ? (
                        <RHFSelect
                          fullWidth
                          name="courrier_info.code"
                          onChange={(e) => {
                            const courrier = data.find(
                              (c: any) => c.code === e.target.value
                            );
                            methods.setValue("courrier_info", {
                              ...methods.getValues("courrier_info"),
                              code: courrier.code,
                              name: courrier.name,
                            });
                          }}
                        >
                          {data?.map((k: any) => (
                            <MenuItem key={k.code} value={k.code}>
                              {k.name}
                            </MenuItem>
                          ))}
                        </RHFSelect>
                      ) : (
                        <RHFTextField
                          inputProps={{
                            readOnly: true,
                          }}
                          name="courrier_info.name"
                        />
                      )}
                    </Stack>
                    <Stack>
                      <Typography>Método de pago</Typography>
                      <TextField
                        value={
                          PaymentMethod[
                            order?.payment_information?.payment_method ?? 1
                          ]
                        }
                      />
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <SectionLinesService order={order} />
        </Stack>
      </FormProvider>
      <Stack direction="row" gap={1}>
        <SectionComments order={order} />
        <SectionDocuments order={order} />
      </Stack>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!methods.formState.isDirty || !canEditCourrier()}
        onClick={() => {
          editCourrierMutation.mutate(methods.getValues());
        }}
      >
        Editar orden
      </Button>
    </Stack>
  );
}

export function HistoryOrder() {
  return <Box>Historial</Box>;
}
