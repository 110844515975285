import { DialogChangeStatus } from "./DialogChangeStatus";
import { DialogComment } from "./DialogComment";
import DialogDetail from "./DialogDetail";
import DialogItems from "./DialogItems";
import { DialogViewInvoice } from "./DialogViewInvoice";
import { DialogNewOrder } from "./new-order/DialogNewOrder";
import { ChangeCourrierDialog } from "./ChangeCourrierDialog";

export const DialogMain = () => {
  return (
    <>
      <DialogItems />
      <DialogDetail />
      <DialogChangeStatus />
      <DialogNewOrder />
      <DialogComment />
      <DialogViewInvoice />
      <ChangeCourrierDialog />
    </>
  );
};
