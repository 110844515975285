import { useState } from "react";

export default function useResetKey() {
  const ramdonKey = () => (Math.random() * 100000000000).toString();

  const [key, setKey] = useState<string>(ramdonKey());

  const resetK = () => {
    setKey(ramdonKey());
  };

  return { key, resetK };
}
