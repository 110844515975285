import { Alert, Snackbar } from "@mui/material";
import { useSelector } from "react-redux";
import { AlertState } from "../../@types/order";
import { StoreState } from "../../redux/rootReducer";
import { setAlert } from "../../redux/slices/orders";

enum E {
  E000 = "E000",
  E001 = "E001",
  E002 = "E002",
  E003 = "E003",
  E004 = "E004",
  E005 = "E005",
  E006 = "E006",
  E007 = "E007",
  E008 = "E008",
  E009 = "E009",
  E010 = "E010",
  E011 = "E011",
  E012 = "E012",
  E013 = "E013",
  E014 = "E014",
  E015 = "E015",
  E100 = "E100",
}

type Error<T extends string = E> = {
  [K in T]: AlertState;
};

export const Errors: Error<E> = {
  E000: { open: false } as AlertState,
  E001: {
    message: "Estado modificado con éxito",
    open: true,
    type: "success",
  },
  E002: {
    type: "info",
    message: "No se pudo modificar el estado de las órdenes",
    open: true,
  },
  E003: {
    type: "info",
    message: "No se puede eliminar las órdenes",
    open: true,
  },
  E004: {
    type: "info",
    message: "No se puede cancelar las órdenes",
    open: true,
  },
  E005: {
    type: "error",
    message: "No se pudo agregar el comentario",
    open: true,
  },
  E006: {
    type: "success",
    message: "Comentario agregado con éxito",
    open: true,
  },
  E007: {
    type: "success",
    message: "Carga realizada correctamente",
    open: true,
  },
  E008: {
    type: "error",
    message: "Cliente no encontrado",
    open: true,
  },
  E009: {
    type: "success",
    message: "Courrier modificado con éxito",
    open: true,
  },
  E010: {
    type: "error",
    message: "No se puedo modificar el courrier",
    open: true,
  },
  E011: {
    type: "error",
    message: "Las órdenes seleccionadas no tienen facturas para descargar",
    open: true,
  },
  E012: {
    type: "error",
    message: "Ha ocurrido un error al intentar generar las etiquetas",
    open: true,
  },
  E013: {
    type: "error",
    message: "Ha ocurrido un error al intentar descargar las facturas",
    open: true,
  },
  E014: {
    type: "error",
    message: "Ha ocurrido un error al intentar descargar el reporte",
    open: true,
  },
  E015: {
    type: "error",
    message: "Por favor primero seleccione un filtro para descargar el reporte",
    open: true,
  },
  E100: {
    type: "error",
    message: "Ha ocurrido un error al intentar la acción",
    open: true,
  },
};

export const SnackAlert = () => {
  const error = useSelector<StoreState, AlertState>(
    (state) => state.orders.error
  );

  const handleCloseSnack = () => {
    setAlert(Errors.E000);
  };
  return (
    <Snackbar
      open={error.open}
      autoHideDuration={6000}
      onClose={handleCloseSnack}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
    >
      <Alert
        onClose={handleCloseSnack}
        severity={error.type}
        sx={{ width: "100%" }}
      >
        {error.message}
      </Alert>
    </Snackbar>
  );
};
