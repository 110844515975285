import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import NotistackProvider from "./components/NotistackProvider";
import ThemeProvider from "./theme";
import { store } from "./redux/store";
import OrderList from "./containers/order-list/OrderList";
import { FirebaseAppProvider } from "reactfire";
import { firebaseConfig } from "./config";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient();

const Root = () => {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
          <ReduxProvider store={store}>
            <ThemeProvider>
              <NotistackProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path="/orders" element={<OrderList />} />
                  </Routes>
                </BrowserRouter>
              </NotistackProvider>
            </ThemeProvider>
          </ReduxProvider>
        </FirebaseAppProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

export default Root;
