import {
  // createFilterOptions,
  MenuItem,
  SxProps,
  Theme,
} from "@mui/material";
import React from "react";
// import { useFormContext } from "react-hook-form";
import { countries } from "../../_data/CountryCode";
import RHFSelect from "./RHFSelect";

type Props = {
  name: string;
  sx?: SxProps<Theme>;
  editable?: boolean;
};

export const RHFAutocompleteCountryCode = ({
  name,
  editable,
  ...rest
}: Props) => {
  // const { control } = useFormContext();
  // const filterOptions = createFilterOptions({
  // 	matchFrom: "any",
  // 	stringify: (option: any) => {
  // 		return option.phone + option.label;
  // 	},
  // });
  return (
    <RHFSelect name={name} {...rest} label="Código de pais">
      {countries.map((c) => (
        <MenuItem key={c.phone} value={`+${c.phone}`}>
          {c.phone}
        </MenuItem>
      ))}
    </RHFSelect>

    // <Controller
    // 	name={name}
    // 	control={control}
    // 	render={({ field, fieldState: { error } }) => (
    // 		<Autocomplete
    // 			{...field}
    // 			{...rest}
    // 			onChange={(_, value) => {
    // 				field.onChange(value?.phone);
    // 			}}
    // 			options={countries}
    // 			autoHighlight
    // 			getOptionLabel={(o) => {
    // 				// console.log(o);
    // 				return `${o.phone}`;
    // 			}}
    // 			disabled={!editable}
    // 			filterOptions={filterOptions}
    // 			fullWidth
    // 			freeSolo
    // 			renderOption={(props, option) => (
    // 				<Box
    // 					component="li"
    // 					sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
    // 					{...props}
    // 				>
    // 					<img
    // 						loading="lazy"
    // 						width="20"
    // 						src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
    // 						srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
    // 						alt=""
    // 					/>
    // 					{option.label} ({option.code}) +{option.phone}
    // 				</Box>
    // 			)}
    // 			renderInput={(params) => (
    // 				<TextField
    // 					{...params}
    // 					label="Código de pais"
    // 					error={!!error}
    // 					helperText={error?.message}
    // 					fullWidth
    // 					inputProps={{
    // 						...params.inputProps,
    // 						autoComplete: "new-password", // disable autocomplete and autofill
    // 						readOnly: !editable,
    // 					}}
    // 				/>
    // 			)}
    // 		/>
    // 	)}
    // />
  );
};
