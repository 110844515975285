import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { RHFSelect } from "../../../../components/hook-form";
import Iconify from "../../../../components/Iconify";
import { OrderType, PaymentMethod } from "../../list/UserTableRow";
import axios from "../../../../utils/axios";
import { useEffect, useState } from "react";
import { COVERAGE_PATH } from "../../../../constants/paths";
import { useFormContext } from "react-hook-form";

export const SectionOrderData = () => {
  const [courriers, setCourriers] = useState<any[]>([]);
  const form = useFormContext();
  useEffect(() => {
    axios.get(`${COVERAGE_PATH}/courriers`).then((r) => {
      setCourriers(r.data);
    });
  }, []);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<Iconify icon="akar-icons:chevron-down" />}>
        <Typography variant="subtitle1">Datos de la orden</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-around"
          gap={{ xs: 2, md: 4 }}
        >
          <Stack direction="row" flex={1} gap={1}>
            <Typography variant="body2" width={86}>
              Tipo de envío
            </Typography>
            <Stack flex={1}>
              <RHFSelect fullWidth name="order_information.shipping_type">
                <MenuItem value="Normal">Normal</MenuItem>
                <MenuItem value="Ecológico">Ecológico</MenuItem>
                <MenuItem value="Express">Express</MenuItem>
              </RHFSelect>
              <RHFSelect
                fullWidth
                name="order_information.type_order"
                placeholder="Tipo de envío"
              >
                {Object.keys(OrderType).map((k) => (
                  <MenuItem key={k} value={k}>
                    {OrderType[k]}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>
          </Stack>
          <Stack direction="row" flex={1} gap={1}>
            <Typography variant="body2">Courrier</Typography>
            <Stack flex={1}>
              <RHFSelect
                fullWidth
                name="courrier_info.code"
                onChange={(e) => {
                  const courrier = courriers.find(
                    (c) => c.code === e.target.value
                  );
                  form.reset({ ...form.getValues(), courrier_info: courrier });
                }}
              >
                {courriers.map((k) => (
                  <MenuItem key={k.code} value={k.code}>
                    {k.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>
          </Stack>
          <Stack direction="row" flex={1} gap={1}>
            <Typography variant="body2">Método de pago</Typography>
            <Stack flex={1}>
              <RHFSelect fullWidth name="payment_information.payment_method">
                {Object.keys(PaymentMethod).map((k) => (
                  <MenuItem key={k} value={k}>
                    {PaymentMethod[k]}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
