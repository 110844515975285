import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Order } from "../../../@types/order";
import Iconify from "../../../components/Iconify";
import axios from "../../../utils/axios";
import { saveAs } from "file-saver";
import {
  closeLoading,
  openLoading,
  setAlert,
} from "../../../redux/slices/orders";
import { Errors } from "../SnackAlert";
import { StatusesEnum } from "../../../enums/StatusesEnum";
import { ORDER_PATH } from "../../../constants/paths";

type Props = {
  order?: Order;
};

export const SectionDocuments = ({ order }: Props) => {
  const handleOrderInvoice = async () => {
    try {
      openLoading();
      const response = await axios.get(
        `${ORDER_PATH}/admin/orders/${order?.uuid}/invoice`,
        {
          responseType: "blob",
        }
      );
      saveAs(response.data, "Factura.pdf");
    } catch (e: any) {
      const json = JSON.parse(await e.text());
      setAlert({ message: json.message, open: true, type: "error" });
    } finally {
      closeLoading();
    }
  };

  const handleOrderLabel = async () => {
    try {
      openLoading();
      const response = await axios.post(
        `${ORDER_PATH}/orders/label`,
        { uuids: [order?.uuid] },
        {
          responseType: "blob",
        }
      );
      saveAs(response.data, `Etiqueta-${order?.uuid}.pdf`);
    } catch (e) {
      setAlert(Errors.E100);
    } finally {
      closeLoading();
    }
  };

  const notCanGenerateLabel = () => {
    return [
      StatusesEnum.CANCELLED,
      StatusesEnum.PENDING,
      StatusesEnum.CREATED,
    ].includes(order?.status as StatusesEnum);
  };

  return (
    <Stack flex={1}>
      <Card>
        <CardHeader
          title={
            <Typography variant="h6" color="primary">
              Documentos
            </Typography>
          }
        />
        <CardContent>
          <Stack alignItems="flex-start" gap={1}>
            <Button
              variant="text"
              startIcon={<Iconify icon="fa6-regular:file-pdf" />}
              onClick={handleOrderInvoice}
            >
              <Typography>Factura cliente</Typography>
            </Button>
            <Tooltip
              title={
                notCanGenerateLabel()
                  ? "No se puede generar la etiqueta, el estado actual no lo permite"
                  : ""
              }
            >
              <Button
                variant="text"
                startIcon={<Iconify icon="quill:label" />}
                onClick={handleOrderLabel}
                {...(notCanGenerateLabel() ? { disabled: true } : {})}
                sx={{ "&.Mui-disabled": { pointerEvents: "all" } }}
              >
                <Typography>Etiqueta orden</Typography>
              </Button>
            </Tooltip>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};
