import { Errors } from "./../../sections/orders/SnackAlert";
import { Item, DialogType, AlertState, Comment } from "./../../@types/order";
import { createSlice } from "@reduxjs/toolkit";

import { dispatch } from "../store";
import axios from "../../utils/axios";
import { Order, OrderState } from "../../@types/order";
import { Category } from "./../../@types/category";
import { saveAs } from "file-saver";
import { COMPANY_PATH, COVERAGE_PATH, ORDER_PATH, ORDER_STATUS } from "../../constants/paths";
import { OrderFilterResponse } from "../../@types/order-filter-response";
import { AutocompleteItems } from "../../@types/autocompleteItems";
import { store } from "../store";
import { Orders } from "../../sections/orders/dialog/DialogChangeStatus";
const path = ORDER_PATH;
const pathCourriers = COVERAGE_PATH;
const pathCompany = COMPANY_PATH;
const pathStatus = ORDER_STATUS;

const initialState: OrderState = {
    categories: { id: "", categories: [] },
    orders: [],
    dialogItems: {
        open: false,
        data: [],
    },
    dialogOrderDetail: {
        open: false,
        data: undefined,
    },
    removeSelected: false,
    noData: false,
    loading: false,
    dialogChangeStatus: {
        open: false,
        data: [],
    },
    error: {
        open: false,
        type: "success",
        message: "",
    },
    dialogNewOrder: {
        open: false,
    },
    dialogComment: {
        open: false,
    },
    dialogViewInvoice: {
        open: false,
        data: "",
    },
    pageInfo: {
        count: 0,
        totalCount: 0,
        endCursor: "",
        startCursor: "",
    },
    loadingData: true,
    autocomplete: [],
    params: {},
    changeCourrierDialog: {
        open: false,
    }
};

const slice = createSlice({
    name: "order",
    initialState,
    reducers: {
        setLoadingData(state, action) {
            state.loadingData = action.payload;
        },
        setCategories(state, action) {
            state.categories = action.payload;
        },
        setOrders(state, action) {
            state.orders = action.payload.orders;
            state.pageInfo = action.payload.info;
        },
        setAutocomplete(state, action) {
            state.autocomplete = action.payload;
        },
        dialogItems(state, actions) {
            state.dialogItems = actions.payload;
        },
        removeOrdersStore(state, action: { payload: string[] }) {
            state.orders = state.orders.filter(
                (order) => !action.payload.includes(order.uuid)
            );
        },
        dialogDetail(state, actions: { payload: DialogType<Order> }) {
            state.dialogOrderDetail = {
                ...state.dialogOrderDetail,
                ...actions.payload,
            };
        },
        removeSelected(state, actions: { payload: boolean }) {
            state.removeSelected = actions.payload;
        },
        setNoData(state, actions: { payload: boolean }) {
            state.noData = actions.payload;
        },
        setLoading(state, actions: { payload: boolean }) {
            state.loading = actions.payload;
        },
        dialogNewOrder(state, actions: { payload: DialogType<any> }) {
            state.dialogNewOrder = actions.payload;
        },
        dialogComment(state, actions: { payload: DialogType<Order> }) {
            state.dialogComment = actions.payload;
        },
        dialogChangeStatus(state, actions: { payload: DialogType<Order[]> }) {
            state.dialogChangeStatus = actions.payload;
        },
        dialogViewInvoice(state, actions) {
            state.dialogViewInvoice = actions.payload;
        },
        changeStatusLocal(
            state,
            action: { payload: { orders: string[]; status: string } }
        ) {
            state.orders = state.orders.map((order) =>
                action.payload.orders.includes(order.uuid)
                    ? { ...order, status: action.payload.status }
                    : order
            );
        },
        setError(state, action) {
            state.error = action.payload;
        },
        addCommentLocal(
            state,
            action: { payload: { uuid: string; comment: Comment } }
        ) {
            state.orders = state.orders.map((order) => {
                if (order.uuid === action.payload.uuid) {
                    return {
                        ...order,
                        comments: [...(order.comments ?? []), action.payload.comment],
                    };
                }
                return order;
            });
        },
        setPrams(state, action) {
            state.params = action.payload;
        },
        changeCourrierDialog(state, action: { payload: DialogType<Order[]> }) {
            state.changeCourrierDialog = action.payload
        }
    },
});

export default slice.reducer;

export function searchOrders(params: Record<string, any>) {
    dispatch(slice.actions.setNoData(false));
    dispatch(slice.actions.setPrams(params));

    return async () => {
        try {
            const filters = { ...params };
            for (const key in filters) {
                filters[key] = filters[key].value ?? filters[key];
            }
            const response = await axios.get<OrderFilterResponse>(
                `${path}/search/orders`,
                { params: filters }
            );
            const nodata = response.data.orders.edges.length === 0;
            dispatch(slice.actions.setNoData(nodata));
            const orders = response.data.orders.edges.map((o) => o.node);
            dispatch(
                slice.actions.setOrders({ orders, info: response.data.orders.pageInfo })
            );
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(slice.actions.setLoadingData(false));
        }
    };
}

export function cleanOrder() {
    dispatch(
        slice.actions.setOrders({ orders: [], info: initialState.pageInfo })
    );
}

export function setLoadingData(loading: boolean) {
    dispatch(slice.actions.setLoadingData(loading));
}

export function getCategoriesFilter() {
    return async () => {
        try {
            const response = await axios.get<Category[]>(
                `${path}/search/categories?type=ORDERS`
            );
            dispatch(slice.actions.setCategories(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function cleanAutocomplete() {
    dispatch(slice.actions.setAutocomplete([]));
}

export function generateLabel(uuids: string[], documentId: string) {
    return async () => {
        try {
            dispatch(slice.actions.setLoading(true));
            await axios.post(
                `${path}/admin/orders/label`,
                { uuids, documentId }
            );
        } catch (e) {
            setAlert(Errors.E012);
        }
    };
}

export function validateReporstPrams() {
    let date;
    let uuid;
    for (const key in store.getState().orders.params) {
        if (key === "order_information.date") {
            date =
                store.getState().orders.params[key].value ??
                store.getState().orders.params[key];
        }
        if (key === "uuid") {
            uuid =
                store.getState().orders.params[key].value ??
                store.getState().orders.params[key];
        }
    }
    if (uuid === undefined && date === undefined) {
        setAlert(Errors.E015);
        return;
    }
    dispatch(generateReports({ date, uuid, param: "params" }));
}

export function validateReportsUuids(uuids: any) {
    const uuid = uuids.map((uuid: any) => uuid.uuid);
    dispatch(generateReports({ uuid: uuid.join(","), param: "uuids" }));
}

export function generateReports(data: any) {
    return async () => {
        try {
            let response: any;
            dispatch(slice.actions.setLoading(true));
            if (data.param === "params") {
                response = await axios.get(`${path}/reports/orders-list`, {
                    params: { rangeDate: data.date, uuid: data.uuid },
                    responseType: "blob",
                });
            } else {
                response = await axios.get(`${path}/reports/orders-list`, {
                    params: { uuid: data.uuid },
                    responseType: "blob",
                });
            }
            saveAs(response.data, "report.xlsx");
        } catch (e) {
            setAlert(Errors.E014);
        } finally {
            dispatch(slice.actions.setLoading(false));
        }
    };
}

export function changeCourrier(uuids: any, courrier_info: any) {
    const uuid = uuids.map((uuid: any) => uuid.uuid);
    return async () => {
        dispatch(slice.actions.setLoading(true))
        try {
            await axios.patch(`${path}/order-courrier`, { uuids: uuid, courrier_info })
            setAlert(Errors.E009);
        } catch (e) {
            setAlert(Errors.E010);
        } finally {
            dispatch(slice.actions.setLoading(false))
            dispatch(slice.actions.changeCourrierDialog({ open: false }))
        }
    }
}

export function generateInvoice(uuids: string[], documentId: string) {
    return async () => {
        try {
            await axios.post(`${path}/admin/orders/invoice`, { uuids, documentId });
        } catch (e) {
            setAlert(Errors.E013);
        }
    };
}

export function changeStatus(orders: Orders) {
    return async () => {
        try {
            await axios.post(
                `${pathStatus}/update-status-massive`,
                { uuids: orders.uuids, status: orders.status }
            );

        } catch (error: any) {
            const jsonResponse: any = JSON.parse(error.message)
            setAlert({ message: jsonResponse.validacionesMensage, open: true, type: "error" });
        } finally {
            closeLoading()
        }
    }
}

export function openDialogItems(data: Item[]) {
    return async () => {
        dispatch(slice.actions.dialogItems({ data, open: true }));
    };
}

export function closeDialogItems() {
    return async () => {
        dispatch(slice.actions.dialogItems({ data: [], open: false }));
    };
}

export function openDialogNewOrder() {
    return async () => {
        dispatch(slice.actions.dialogNewOrder({ open: true }));
    };
}

export function closeDialogNewOrder() {
    return async () => {
        dispatch(slice.actions.dialogNewOrder({ open: false }));
    };
}

export function openDialogComment(data: Order) {
    return async () => {
        dispatch(slice.actions.dialogComment({ open: true, data }));
    };
}

export function closeDialogComment() {
    return async () => {
        dispatch(slice.actions.dialogComment({ open: false }));
    };
}

export function openDialogViewInvoice(data: string) {
    return async () => {
        dispatch(slice.actions.dialogViewInvoice({ open: true, data }));
    };
}

export function closeDialogViewInvoice() {
    return async () => {
        dispatch(slice.actions.dialogViewInvoice({ open: false }));
    };
}

export function openChangeCourrierDialog(data?: any) {
    return async () => {
        dispatch(slice.actions.changeCourrierDialog({ open: true, data }))
    }
}

export function closeChangeCourrierDialog() {
    return async () => {
        dispatch(slice.actions.changeCourrierDialog({ open: false }))
    }
}

export function deleteOrders(orders: string[]) {
    return async () => {
        try {
            dispatch(slice.actions.setLoading(true));
            await axios.post(`${path}/delete`, { orders });
            dispatch(slice.actions.removeOrdersStore(orders));
            dispatch(slice.actions.removeSelected(true));
        } catch (e) {
            setAlert(Errors.E100);
        } finally {
            dispatch(slice.actions.setLoading(false));
        }
    };
}

export function cancelOrders(orders: string[]) {
    return async () => {
        try {
            dispatch(slice.actions.setLoading(true));
            await axios.post(`${path}/cancel`, { orders });
            dispatch(
                slice.actions.changeStatusLocal({ orders, status: "CANCELLED" })
            );
        } catch (e) {
            setAlert(Errors.E100);
        } finally {
            dispatch(slice.actions.setLoading(false));
        }
    };
}

export function openDialogDetail(order: Order) {
    return async () => {
        dispatch(slice.actions.dialogDetail({ data: order, open: true }));
    };
}

export function closeDialogDetail() {
    return async () => {
        dispatch(slice.actions.dialogDetail({ open: false }));
    };
}

export function openLoading() {
    dispatch(slice.actions.setLoading(true));
}

export function closeLoading() {
    dispatch(slice.actions.setLoading(false));
}

export function openDialogChangeStatus(orders: Order[]) {
    dispatch(slice.actions.dialogChangeStatus({ open: true, data: orders }));
}

export function closeDialogChangeStatus() {
    return async () => {
        dispatch(slice.actions.dialogChangeStatus({ open: false }));
    };
}

export function changeStatusLocal(orders: string[], status: string) {
    dispatch(slice.actions.changeStatusLocal({ orders, status }));
}

export function setAlert(error: AlertState) {
    dispatch(slice.actions.setError(error));
}

export function addComment(c: Comment, uuid: string) {
    return async () => {
        try {
            const { comment, date } = c;
            dispatch(slice.actions.setLoading(true));
            await axios.patch(`${path}/order-comments`, { uuid, comment, date });
            dispatch(slice.actions.addCommentLocal({ uuid, comment: c }));
            setAlert(Errors.E006);
        } catch (e) {
            setAlert(Errors.E005);
        } finally {
            dispatch(slice.actions.setLoading(false));
        }
    };
}

export function uploadBatchFile(formData: FormData) {
    return async () => {
        try {
            openLoading();
            await axios.post(`${path}/batch-orders`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            await dispatch(searchOrders({}));
            setAlert(Errors.E007);
        } catch (e: any) {
            setAlert({ message: e.message, open: true, type: "error" });
            // closeLoading()
        }
    };
}

export function uploadOrderStateFile(formData: FormData) {
    return async () => {
        try {
            await axios.post(`${path}/charge-orders-state`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            await dispatch(searchOrders({}));
            setAlert(Errors.E007);
        } catch (e: any) {
            setAlert({ message: e.message, open: true, type: "error" });
        }
    };
}

export function getCourriers() {
    return async () => {
        try {
            const response = await axios.get<AutocompleteItems[]>(
                `${pathCourriers}/courriers`
            );
            dispatch(slice.actions.setAutocomplete(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getCompany() {
    return async () => {
        try {
            const response = await axios.get<AutocompleteItems[]>(
                `${pathCompany}/all-companies`
            );
            dispatch(slice.actions.setAutocomplete(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}
