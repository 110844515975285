import { useEffect, useState } from "react";
import { UseFormReturn, FieldValues } from "react-hook-form";
import { Item } from "../@types/order";

type Props = {
  form: UseFormReturn<FieldValues, any>;
};

export default function useProduct({ form }: Props) {
  const order = form.watch();
  const [totalProducts, setTotalProducts] = useState(0);

  useEffect(() => {
    calculeTotalProducts();
  }, [order]);

  function calculeTotalProducts() {
    const total =
      order?.items?.reduce(
        (p: number, c: Item) => p + (c.price ?? 0) * (c.quantity ?? 0),
        0
      ) ?? 0;
    setTotalProducts(total);
    return total;
  }

  const calculateTotal = (item: Item) => {
    const total = (item.price ?? 0) * (item.quantity ?? 0);
    return total.toFixed(2);
  };

  return { totalProducts, calculateTotal, calculeTotalProducts };
}
