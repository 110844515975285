import { paramCase } from "change-case";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Box,
	Card,
	Table,
	Tooltip,
	TableBody,
	Container,
	IconButton,
	TableContainer,
	TablePagination,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import useTable, { getComparator } from "../../hooks/useTable";
import { _userList } from "../../_mock";
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import {
	TableNoData,
	TableHeadCustom,
	TableSelectedActions,
	TableSkeleton,
} from "../../components/table";
import UserTableRow from "../../sections/orders/list/UserTableRow";
import OrderFilter from "../../sections/orders/filter/OrderFilter";
import { useDispatch } from "../../redux/store";
import {
	cancelOrders,
	deleteOrders,
	getCategoriesFilter,
	openChangeCourrierDialog,
	openDialogChangeStatus,
	openLoading,
	setAlert,
	validateReportsUuids,
} from "../../redux/slices/orders";
import { Order, OrderState } from "../../@types/order";
import { StoreState } from "../../redux/rootReducer";
import { useSelector } from "react-redux";
import LoadingScreen from "../../components/LoadingScreen";
import { Errors, SnackAlert } from "../../sections/orders/SnackAlert";
import { StatusesEnum } from "../../enums/StatusesEnum";
import { TABLE_HEAD } from "../../constants/OrderHeadTable";
import { DialogMain } from "../../sections/orders/dialog/DialogMain";

import { FirestoreProvider, useFirebaseApp } from "reactfire";
import { getFirestore } from "firebase/firestore";
import { isValidToken } from "../../utils/auth";
import useRequireInvoices, { TypeDocuments } from "../../hooks/useRequireInvoices";
import { OrderSelected } from "../../hooks/useTable";

const collectionInvoice = `${process.env.STAGE}-invoices-downloads`;
const collectionLabel = `${process.env.STAGE}-labels-downloads`;

export default function OrderList() {
	if (!isValidToken(localStorage.getItem("accessToken")!)) {
		window.location.href = "/auth";
	}

	const {
		dense,
		page,
		order,
		orderBy,
		rowsPerPage,
		selected,
		setSelected,
		onSelectRow,
		onSelectAllRows,
		onSort,
		onChangePage,
		onChangeRowsPerPage,
	} = useTable({ defaultRowsPerPage: 25 });

	const { orders, removeSelected, noData, loading, pageInfo, loadingData } =
		useSelector<StoreState, OrderState>((state) => state.orders);

	const [action, setAction] = useState<number>();

	const navigate = useNavigate();

	const [tableData, setTableData] = useState(orders);

	const handleDeleteRows = () => {
		const ordersFilter = _filterOrders([
			StatusesEnum.MODIFYING,
			StatusesEnum.PENDING,
			StatusesEnum.CREATED,
		]);
		if (ordersFilter.length > 0) {
			dispatch(deleteOrders(ordersFilter.map((o) => o.uuid)));
		} else {
			setAlert(Errors.E003);
		}
	};

	const _filterOrders = (statusFilter: string[], validation: boolean = true) =>
		selected.filter((order) => {
			return statusFilter.includes(`${order?.status}`) === validation;
		});

	const handleGenerateLabel = () => {
		const filterAllowed = _filterOrders(
			[StatusesEnum.CANCELLED, StatusesEnum.PENDING, StatusesEnum.CREATED],
			false
		);
		if (filterAllowed.length > 0) {
			handleRequireGenerateInvoice(filterAllowed.map(o => o.uuid), TypeDocuments.LABEL, collectionLabel)
		} else {
			setAlert(Errors.E001);
		}
	};

	const handleGenerateReports = () => {
		validateReportsUuids(selected);
	};

	const handleCancelOrder = () => {
		const ordersfilter = _filterOrders([
			StatusesEnum.MODIFYING,
			StatusesEnum.CREATED,
			StatusesEnum.PENDING,
			StatusesEnum.PICKING,
			StatusesEnum.PACKING,
			StatusesEnum.READYTOSHIP,
		]);
		if (ordersfilter.length > 0) {
			dispatch(cancelOrders(ordersfilter.map((o) => o.uuid)));
		} else {
			setAlert(Errors.E004);
		}
	};

	const handleGenerateInvoice = () => {
		const findOrder = selected.filter((order) => {
			const haveInvoice = !!order.invoice;
			return order.uuid && haveInvoice;
		});

		const uuids = findOrder.map((o) => o.uuid);

		if (uuids.length === 0) {
			setAlert(Errors.E011);
			return;
		}
		openLoading();
		handleRequireGenerateInvoice(uuids, TypeDocuments.INVOICE, collectionInvoice);
	};

	const handleEditRow = (id: string) => {
		navigate(PATH_DASHBOARD.user.edit(paramCase(id)));
	};

	const dataFiltered = applySortFilter({
		tableData,
		comparator: getComparator(order, orderBy),
	});

	const onOpenDialogChangeStatus = () => {
		const mapOrdersSelected: Order[] = selected.map((selectedOrder: OrderSelected) => {
			return (
				({ uuid: selectedOrder.uuid, status: selectedOrder.status } as Order)
			);
		});
		openDialogChangeStatus(mapOrdersSelected);
	};

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCategoriesFilter());
	}, [dispatch]);

	useEffect(() => {
		if (removeSelected) {
			setSelected([]);
		}
	}, [removeSelected]);

	useEffect(() => {
		setTableData(orders);
	}, [orders]);

	const firestore = getFirestore(useFirebaseApp());

	const { handleRequireGenerateInvoice } = useRequireInvoices({
		firestore,
		method: "ORDERS",
	});
	const handleChangeCourrier = () => {

		dispatch(openChangeCourrierDialog(selected));
	}
	return (
		<FirestoreProvider sdk={firestore}>
			<Page height="calc(100vh - 30px)">
				<Container
					maxWidth={false}
					sx={{ height: "100%", display: "flex", flexDirection: "column" }}
				>
					<HeaderBreadcrumbs
						heading="Órdenes"
						links={[
							{ name: "Órdenes", href: PATH_DASHBOARD.orders.root },
							{ name: "Listado de órdenes" },
						]}
					/>

					<Card sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
						<OrderFilter limit={rowsPerPage} page={page} action={action} />

						{selected.length > 0 && (
							<TableSelectedActions
								dense={dense}
								numSelected={selected.length}
								rowCount={tableData.length}
								actions={
									<>
										<Tooltip title="Cancelar">
											<IconButton
												color="primary"
												onClick={() => handleCancelOrder()}
											>
												<Iconify icon={"typcn:cancel"} />
											</IconButton>
										</Tooltip>
										<Tooltip title="Eliminar">
											<IconButton
												color="primary"
												onClick={() => handleDeleteRows()}
											>
												<Iconify icon={"eva:trash-2-outline"} />
											</IconButton>
										</Tooltip>
										<Tooltip title="Cambiar estado">
											<IconButton
												color="primary"
												onClick={onOpenDialogChangeStatus}
											>
												<Iconify icon={"tabler:status-change"} />
											</IconButton>
										</Tooltip>
										<Tooltip title="Generar etiquetas">
											<IconButton color="primary" onClick={handleGenerateLabel}>
												<Iconify icon={"fluent:style-guide-20-filled"} />
											</IconButton>
										</Tooltip>
										<Tooltip title="Generar reporte">
											<IconButton
												color="primary"
												onClick={handleGenerateReports}
											>
												<Iconify icon={"mdi:report-box"} />
											</IconButton>
										</Tooltip>
										<Tooltip title="Generar facturas">
											<IconButton
												color="primary"
												onClick={handleGenerateInvoice}
											>
												<Iconify icon={"tabler:file-invoice"} />
											</IconButton>
										</Tooltip>
										<Tooltip title="Cambiar de courrier">
											<IconButton color="primary" onClick={handleChangeCourrier}>
												<Iconify icon={"mdi:human-hand-truck"} />
											</IconButton>
										</Tooltip>
									</>
								}
							/>
						)}

						<TableContainer sx={{ position: "relative", flex: 1 }}>
							<Table size={dense ? "small" : "medium"} stickyHeader>
								<TableHeadCustom
									order={order}
									orderBy={orderBy}
									headLabel={TABLE_HEAD}
									rowCount={tableData.length}
									numSelected={selected.length}
									onSort={onSort}
									onSelectAllRows={() =>
										onSelectAllRows(
											tableData.map((row) => ({ uuid: row.uuid, status: row.status, date: row.order_information.date, invoice: `${row.invoice_url}` }))
										)
									}
									sx={{ zIndex: 10 }}
								/>

								<TableBody>
									{dataFiltered.map((row) => (
										<UserTableRow
											key={row.uuid}
											row={row}
											selected={selected.map((order) => order.uuid).includes(row.uuid)}
											onSelectRow={() => onSelectRow({ uuid: row.uuid, status: row.status, date: row.order_information.date, invoice: `${row.invoice_url}` })}
											onEditRow={() => handleEditRow(row.uuid)}
										/>
									))}
									<TableNoData isNotFound={noData} />
									{loadingData ? <TableSkeleton /> : null}
									{loadingData ? <TableSkeleton /> : null}
								</TableBody>
							</Table>
						</TableContainer>

						<Box sx={{ position: "relative" }}>
							<TablePagination
								rowsPerPageOptions={[25, 50, 100, 500]}
								component="div"
								count={pageInfo?.totalCount ?? 0}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={(_, newPage) => {
									const _action = page < newPage ? 1 : -1;
									setAction(_action);
									setTimeout(() => {
										setAction(0);
									}, 500);
									onChangePage(_, newPage);
									onChangePage(_, newPage);
								}}
								onRowsPerPageChange={onChangeRowsPerPage}
								labelRowsPerPage={
									<div />
									//todo: not remove, will be used for pagination number
									// 	<Pagination
									// 		count={Math.ceil(dataFiltered.length / rowsPerPage)}
									// 		page={page + 1}
									// 		onChange={(_, _page) => {
									// 			onChangePage(_, _page - 1);
									// 		}}
									// 		hideNextButton
									// 		hidePrevButton
									// 	/>
								}
							/>
						</Box>
					</Card>
				</Container>
				<DialogMain />
				{loading && <LoadingScreen />}
				<SnackAlert />
			</Page>
		</FirestoreProvider>
	);
}

// ----------------------------------------------------------------------

function applySortFilter({
	tableData,
	comparator,
}: {
	tableData: Order[];
	comparator: (a: any, b: any) => number;
}) {
	const stabilizedThis = tableData.map((el, index) => [el, index] as const);

	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	tableData = stabilizedThis.map((el) => el[0]);

	return tableData;
}
