import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "../../../../utils/axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { DialogType, Order } from "../../../../@types/order";
import { FormProvider } from "../../../../components/hook-form";
import Iconify from "../../../../components/Iconify";
import Page from "../../../../components/Page";
import { StoreState } from "../../../../redux/rootReducer";
import {
  closeDialogNewOrder,
  closeLoading,
  openLoading,
  searchOrders,
  setAlert,
} from "../../../../redux/slices/orders";
import { useDispatch } from "../../../../redux/store";
import { SectionClient } from "./SectionClient";
import { SectionInternalNotes } from "./SectionInternalNotes";
import { SectionNewDocuments } from "./SectionNewDocuments";
import { SectionNewLinesService } from "./SectionNewLineService";
import { SectionOrderData } from "./SectionOrderData";
import { SectionProduct } from "./SectionProduct";
import { SectionSearch } from "./SectionSearch";
import { ORDER_PATH } from "../../../../constants/paths";
import { Errors } from "../../SnackAlert";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = Yup.object().shape({
  client: Yup.object().shape({
    identification: Yup.string()
      .required("Identificación es requerido")
      .when("type_identification", {
        is: "04",
        then: Yup.string()
          .matches(/^[0-9]*$/, "El RUC solo puede contener números")
          .min(13, "La identificación debe tener al menos 13 números")
          .max(13, "La identificación debe tener hasta 13 números"),
        otherwise: Yup.string().when("type_identification", {
          is: "05",
          then: Yup.string()
            .matches(
              /^[0-9]*$/,
              "El numero de cédula solo puede contener números"
            )
            .min(10, "La identificación debe tener al menos 10 números")
            .max(10, "La identificación debe tener hasta 10 números"),
          otherwise: Yup.string().when("type_identification", {
            is: "06",
            then: Yup.string()
              .matches(
                /^[A-Za-z0-9]*$/,
                "El pasaporte admite caracteres alfanuméricos"
              )
              .min(8, "La identificación debe tener al menos 8 caracteres")
              .max(20, "La identificación debe tener hasta 20 caracteres"),
            otherwise: Yup.string().max(
              0,
              "Primero debe seleccionar un tipo de identificación"
            ),
          }),
        }),
      }),
    type_identification: Yup.string().required(
      "Tipo de identificacion es requerido"
    ),
    name: Yup.string()
      .required("Nombre requerido")
      .matches(/^[a-zA-Zá-úÁ-ÚÑñ ]*$/, "El nombre solo puede contener letras")
      .min(2, "El nombre debe terner al menos dos caracteres")
      .max(30, "El nombre puede tener hasta 30 caracteres"),
    last_name: Yup.string()
      .required("Apellido requerido")
      .matches(/^[a-zA-Zá-úÁ-ÚÑñ ]*$/, "El apellido solo puede contener letras")
      .min(2, "El apellido debe terner al menos dos caracteres")
      .max(30, "El apellido puede tener hasta 30 caracteres"),
    client_contact_information: Yup.object().shape({
      country_code: Yup.string().required("Código de país es requerido"),
      mobile: Yup.string()
        .required("Requerido")
        .matches(
          /^[0-9]*$/,
          "El numero de telefono solo puede contener numeros"
        )
        .min(9, "El numero de telefono debe tener al menos 9 dígitos")
        .max(15, "El numero de telefono debe tener hasta 15 dígitos"),
      phone: Yup.string()
        .matches(
          /^[0-9]*$/,
          "El numero de telefono solo puede contener numeros"
        )
        .min(6, "El numero de telefono debe tener al menos 6 dígitos")
        .max(15, "El numero de telefono debe tener hasta 15 dígitos"),
      email: Yup.string().email("El correo es inválido").required("Requerido"),
    }),
    client_address_information: Yup.object().shape({
      address1: Yup.string()
        .required("Requerido")
        .matches(
          /^[A-Za-z0-9ÑñÀ-ÖØ-ö\\s,'-/()*á-úÁ-Ú ]*$/,
          "La dirección admite caracteres especiales"
        )
        .min(4, "La dirección debe tener al menos 4 caracteres")
        .max(125, "La dirección debe tener hasta 125 caracteres"),
      house_number: Yup.string()
        .matches(
          /^[A-Za-z0-9ÑñÀ-ÖØ-ö\\s,'-/()*á-úÁ-Ú  ]*$/,
          "El número de casa admite caracteres especiales"
        )
        .min(1, "El numero de casa debe tener al menos 1 caracteres")
        .max(15, "El numero de casa debe tener hasta 15 caracteres"),
      address2: Yup.string()
        .required("Requerido")
        .matches(
          /^[A-Za-z0-9ÑñÀ-ÖØ-ö\\s,'-/()*á-úÁ-Ú ]*$/,
          "El número de casa admite caracteres especiales"
        )
        .min(4, "La dirección debe tener al menos 4 caracteres")
        .max(125, "La dirección debe tener hasta 125 caracteres"),
      reference: Yup.string()
        .required("Requerido")
        .matches(
          /^[A-Za-z0-9ÑñÀ-ÖØ-ö\\s,'-/()*á-úÁ-Ú ]*$/,
          "El número de casa admite caracteres especiales"
        )
        .min(5, "La referencia debe tener al menos 5 caracteres")
        .max(125, "La referencia debe tener hasta 125 caracteres"),
      country: Yup.string().required("Requerido"),
      state: Yup.string().required("Requerido"),
      city: Yup.string().required("Requerido"),
    }),
  }),
  items: Yup.array().of(
    Yup.object().shape({
      quantity: Yup.number().required("Requerido"),
      price: Yup.number().required("Requerido"),
    })
  ),
  order_information: Yup.object().shape({
    type_order: Yup.string().required("Requerido"),
    shipping_type: Yup.string().required("Requerido"),
  }),
  payment_information: Yup.object().shape({
    payment_method: Yup.string().required("Requerido"),
  }),
  company: Yup.object().shape({
    name: Yup.string().required("La compañia es requerida"),
  }),
});

const errorsFieldsMap = {
  "/client/client_contact_information/mobile": "Celular",
  "/client/client_contact_information/phone": "Teléfono",
  "/client/name": "Nombre cliente",
  "/client/last_name": "Apellido cliente",
  "/client": "Identificación",
};

export const DialogNewOrder = () => {
  const dialog = useSelector<StoreState, DialogType<any>>(
    (state) => state.orders.dialogNewOrder
  );

  const theme = useTheme();
  const dispatch = useDispatch();

  const methods = useForm<Order>({
    mode: "onChange",
    resolver: yupResolver(schema),
    shouldUnregister: false,
    defaultValues: {
      client: {
        type_identification: "",
        client_address_information: { country: "", state: "", city: "" },
        client_contact_information: { country_code: "" },
      },
    },
  });
  const { handleSubmit } = methods;

  const [clientExpanded, setClientExpanded] = useState(false);

  const handleCloseDialog = () => {
    methods.reset({ client: { name: "" } }, { keepValues: false });
    dispatch(closeDialogNewOrder());
  };

  const saveOrderMutation = useMutation({
    mutationFn: (order: Order) => {
      let orderNew = { ...order };
      orderNew.items.forEach((i, index) => {
        const { price, quantity, sku, upc, description, weight } = i;
        orderNew.items[index] = {
          upc: `${upc}`,
          price,
          quantity,
          sku,
          description,
          weight,
          total: quantity * price,
        };
      });
      const client = orderNew.client;
      orderNew = {
        ...orderNew,
        client: {
          ...client,
          name: client.name.toUpperCase(),
          last_name: client.last_name.toUpperCase(),
          client_address_information: {
            ...client.client_address_information,
            address1: client.client_address_information.address1.toUpperCase(),
            address2: client.client_address_information.address2.toUpperCase(),
            reference:
              client.client_address_information.reference.toUpperCase(),
            house_number:
              client.client_address_information.house_number.toUpperCase(),
          },
        },
      };
      delete orderNew.hasInvoice;
      return axios.post(`${ORDER_PATH}/admin/orders`, orderNew);
    },
    onMutate: () => openLoading(),
    onSuccess: (resp) => {
      dispatch(searchOrders({})).then(() => {
        closeLoading();
        handleCloseDialog();
        setAlert({
          message: "Orden creada con éxito",
          type: "success",
          open: true,
        });
      });
      console.log(resp);
    },
    onError: (e: any) => {
      closeLoading();
      if (e.details) {
        const firstE = e.details.validation_errors[0];
        const field = errorsFieldsMap[firstE.field] ?? firstE.field;
        setAlert({
          message: `${field}: ${firstE.message}`,
          open: true,
          type: "error",
        });
        return;
      }
      if (e.message) {
        setAlert({
          message: e.message,
          open: true,
          type: "error",
        });
        return;
      }
      setAlert(Errors.E100);
    },
  });

  const handleOnSubmit = async (order: Order) => {
    const validation = await methods.trigger();
    if (validation) {
      saveOrderMutation.mutate(order);
    } else {
      setClientExpanded(true);
    }
  };

  return (
    <Dialog {...dialog} onClose={handleCloseDialog} fullWidth maxWidth="xl">
      <Page sx={{ backgroundColor: theme.palette.grey[100] }}>
        <Stack
          sx={{ backgroundColor: theme.palette.primary.main, p: 2 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4" color={theme.palette.common.white}>
            Nueva orden
          </Typography>
          <IconButton onClick={handleCloseDialog}>
            <Iconify
              icon="ant-design:close-circle-filled"
              sx={{ color: theme.palette.common.white }}
            />
          </IconButton>
        </Stack>
        <Stack p={2} gap={1}>
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(handleOnSubmit)}
          >
            <SectionSearch setExpanded={setClientExpanded} />
            <SectionClient
              expanded={clientExpanded}
              setExpanded={setClientExpanded}
            />
            <SectionProduct />
            <SectionOrderData />
            <Grid container marginRight={0} spacing={2} paddingY={1}>
              <Grid item xs={12} md={7}>
                <Stack gap={1}>
                  <SectionNewDocuments />
                  <SectionInternalNotes />
                </Stack>
              </Grid>
              <Grid item xs={12} md={5}>
                <SectionNewLinesService />
              </Grid>
            </Grid>
            <Stack direction="row" gap={2} py={2} justifyContent="center">
              <Button type="submit" variant="contained" color="primary">
                Crear orden
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseDialog}
              >
                Cancelar
              </Button>
            </Stack>
          </FormProvider>
        </Stack>
      </Page>
    </Dialog>
  );
};
