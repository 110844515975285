import {
    Dialog,
    DialogTitle,
    Stack,
    MenuItem,
    Select,
    Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import { StoreState } from "../../../redux/rootReducer";
import {
    changeCourrier,
    closeChangeCourrierDialog,
} from "../../../redux/slices/orders";
import { useDispatch } from "../../../redux/store";
import { DialogType } from "../../../@types/order";
import { COVERAGE_PATH } from "../../../constants/paths";
import axios from "../../../utils/axios";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const ChangeCourrierDialog = () => {
    const dispatch = useDispatch();
    const { data } = useSelector<StoreState, DialogType<any>>((state) => state.orders.changeCourrierDialog);
    const dialog = useSelector<StoreState, DialogType<any>>((state) => state.orders.changeCourrierDialog);
    const { data: courrier } = useQuery({
        queryKey: ["courriers"],
        queryFn: async () => {
            const response = await axios.get(`${COVERAGE_PATH}/courriers`);
            return response.data;
        },
    });
    const [dataCourrier, setDataCourrier] = useState<any>(data);
    const [courrierSelected, setCourrierSelected] = useState<any>();

    const handleClose = () => {
        dispatch(closeChangeCourrierDialog());
    };

    const changeCourriers = () => {
        const aux = courrier.filter((k: any) => k.code === courrierSelected)
        const courrierInformation = {
            cityCode: "201001002001",
            code: courrierSelected,
            delivery_day: "Lunes a Viernes",
            name: aux[0].name
        }
        dispatch(changeCourrier(data, courrierInformation))

    }
    useEffect(() => {
        setDataCourrier(courrier)
    }, [data]);

    return (
        <Dialog {...dialog} onClose={handleClose} fullWidth>
            <Stack spacing={2} sx={{ p: 2 }}>
                <DialogTitle>Seleccionar courriers</DialogTitle>
                <Select
                    fullWidth
                    name="courrier"
                    value={dialog?.data?.courrier}
                    onChange={(e) => {
                        setCourrierSelected(e.target.value);
                    }}
                >
                    {dataCourrier?.map((k: any) => (
                        <MenuItem key={k.code} value={k.code}>
                            {k.name}
                        </MenuItem>
                    ))}
                </Select>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        changeCourriers();
                    }}
                >
                    Actualizar
                </Button>

            </Stack>
        </Dialog>
    );
};
