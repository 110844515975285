import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Item } from "../../../@types/order";
import Scrollbar from "../../../components/Scrollbar";

export default function TableItem(props: { items: Item[] }) {
  const { items } = props;
  return (
    <Scrollbar>
      <Card>
        <CardContent>
          <TableContainer sx={{ minWidth: 800, position: "relative" }}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell width={100}>Producto</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>UPC</TableCell>
                  <TableCell align="center">Cantidad</TableCell>
                  <TableCell>Bodega</TableCell>
                  <TableCell align="center">Valor</TableCell>
                  <TableCell align="center">Subtotal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ width: "50%" }}>
                      {item.description}
                    </TableCell>
                    <TableCell sx={{ width: "50%", whiteSpace: "nowrap" }}>
                      {item.sku}
                    </TableCell>
                    <TableCell>{item.upc}</TableCell>
                    <TableCell align="center" sx={{ width: 0 }}>
                      {item.quantity}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      Bodega quito
                    </TableCell>
                    <TableCell align="center" sx={{ width: 0 }}>
                      {item.price.toFixed(2)}
                    </TableCell>
                    <TableCell align="center" sx={{ width: 0 }}>
                      {item.total?.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Scrollbar>
  );
}
