import { Dialog, Stack, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { DialogType } from "../../../@types/order";
import Page from "../../../components/Page";
import { StoreState } from "../../../redux/rootReducer";
import { closeDialogViewInvoice } from "../../../redux/slices/orders";
import { useDispatch } from "../../../redux/store";

export const DialogViewInvoice = () => {
  const dialog = useSelector<StoreState, DialogType<any>>(
    (state) => state.orders.dialogViewInvoice
  );
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleCloseDialog = () => {
    dispatch(closeDialogViewInvoice());
  };

  return (
    <Dialog {...dialog} onClose={handleCloseDialog} fullWidth maxWidth="lg">
      <Page sx={{ backgroundColor: theme.palette.grey[100] }}>
        <Stack width="100%" maxHeight={900}>
          <object
            style={{ height: "700px" }}
            {...dialog}
            type="application/pdf"
            width="100%"
          ></object>
        </Stack>
      </Page>
    </Dialog>
  );
};
