import {
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";

import axios from "../../../../utils/axios";
import { Item } from "../../../../@types/order";
import useProduct from "../../../../hooks/useProduct";
import { COVERAGE_PATH } from "../../../../constants/paths";
import { ServiceItem, TotalItem } from "../SectionLineService";
import { PaymentMethod } from "../../list/UserTableRow";

export const SectionNewLinesService = () => {
  const theme = useTheme();
  const form = useFormContext();

  const { totalProducts } = useProduct({ form });

  const order = form.watch();

  const { data } = useQuery({
    queryKey: [
      "shipmentFee",
      {
        company_code: order?.company?.code,
        type_coverage: order?.order_information?.type_coverage,
        weight: order.items?.reduce((p: number, c: Item) => p + c.weight, 0),
      },
    ],
    queryFn: ({ queryKey }) => {
      return axios.post(`${COVERAGE_PATH}/shipping-fee`, queryKey[1]);
    },
    enabled:
      !!order.company?.code &&
      !!order.order_information?.type_coverage &&
      order.items?.reduce((p: number, c: Item) => p + c.weight, 0) > 0,
    refetchOnWindowFocus: false,
    cacheTime: 90000,
    keepPreviousData: true,
  });

  const calculateTotalServices = () => {
    const insurance = totalProducts * 0.01;
    const totalPrintPaste =
      order?.company?.invoice_information?.print_invoice_order ?? 0;
    return insurance + totalPrintPaste;
  };

  const calculateTotalServicesIva = () => {
    return calculateTotalServices() * 0.12;
  };

  const calculateCostShipment = () => {
    return data?.data.total ?? 0;
  };

  return (
    <Card>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ background: theme.palette.primary.main }}
        p={1}
      >
        <Typography variant="h5" color={theme.palette.common.white}>
          Subtotal de productos
        </Typography>
        <Typography variant="subtitle1" color={theme.palette.common.white}>
          {totalProducts}
        </Typography>
      </Stack>
      <CardContent>
        <Typography variant="h5" color={theme.palette.primary.main}>
          Subtotal de productos
        </Typography>
        <Stack gap={1}>
          <ServiceItem
            serviceName="Envío"
            total={calculateCostShipment()}
            value1={order.courrier_info?.name ?? "Sin definir"}
            value2={order.order_information?.shipping_type ?? "Sin definir"}
          />
          <ServiceItem
            serviceName="Seguro"
            total={totalProducts * 0.01}
            value1="Si"
            value2="1%"
          />
          <ServiceItem
            serviceName="Print & Paste"
            total={
              order?.company?.invoice_information?.print_invoice_order ?? 0
            }
            value1={order?.company?.invoice_information?.enabled ? "Sí" : "No"}
          />
          <ServiceItem
            serviceName="Picking & Packing"
            total={
              order?.company?.pick_and_pack_information?.pick_and_pack_order ??
              0
            }
            value1={
              order?.company?.pick_and_pack_information?.enabled ? "Sí" : "No"
            }
          />
          <ServiceItem
            serviceName="Método de pago"
            total={totalProducts}
            value1={
              PaymentMethod[order?.payment_information?.payment_method] ??
              "Sin definir"
            }
            value2={`${totalProducts > 100 ? ">" : "<"}100`}
          />
          <Divider />
          <TotalItem
            total={calculateTotalServices()}
            description="Sutbotal servicios"
          />
          <TotalItem
            total={calculateTotalServicesIva()}
            description="Impuestos"
          />
          <TotalItem
            total={calculateTotalServices() + calculateTotalServicesIva()}
            description="Valor total"
          />
          <Divider />
          <TotalItem
            total={
              order?.payment_information?.payment_method === "1"
                ? totalProducts
                : 0
            }
            description="Valor a recaudar"
            variant="h4"
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
