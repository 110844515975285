export const TABLE_HEAD = [
  {
    id: "name",
    label: "Descripción",
    align: "center",
    icon: "ant-design:exclamation-circle-filled",
  },
  {
    id: "order_information.date",
    label: "Fecha",
    align: "center",
    icon: "akar-icons:calendar",
  },
  {
    id: "company.name",
    label: "Compañia",
    align: "center",
    icon: "clarity:store-solid",
  },
  {
    id: "client.client_address_information.country",
    label: "País",
    align: "center",
    icon: "bx:world",
  },
  {
    id: "uuid",
    label: "Orden",
    align: "center",
    icon: "ant-design:shopping-cart-outlined",
  },
  {
    id: "client.fullname",
    label: "Comprador",
    align: "center",
    icon: "fa:user",
  },
  { id: "items", label: "Items", align: "left" },
  { id: "weight", label: "Paquete", align: "left" },
  { id: "type", label: "Tipo de envio", align: "left" },
  { id: "service", label: "Tarifa de envio", align: "left" },
  { id: "hub.name", label: "Envio desde", align: "left" },
  {
    id: "payment_information.payment_method",
    label: "Método de pago",
    align: "left",
  },
  { id: "order_information.cod", label: "Valor orden", align: "left" },
  { id: "payment_information.status", label: "Cobrado", align: "left" },
  {
    id: "comments",
    label: "Comentarios",
    align: "center",
    icon: "fluent:comment-multiple-24-filled",
  },
  {
    id: "service1",
    label: "Serv. Empresas\nFacturado \t Pagado",
    align: "center",
  },
  {
    id: "service2",
    label: "Serv. Courrier\nFacturado \t Pagado",
    align: "center",
  },
  { id: "status", label: "Estado orden", align: "left", sticky: true },
];
