import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from "../../../../components/hook-form";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import Iconify from "../../../../components/Iconify";
import { RHFAutocompleteCountryCode } from "../../../../components/hook-form/RHFAutocompleteCountryCode";
import { countriesStates } from "../../../../_data/CountryStates";
import React from "react";
import axios from "../../../../utils/axios";
import { COVERAGE_PATH } from "../../../../constants/paths";
import useResetKey from "../../../../hooks/useResetKey";

const TYPE_IDENTIFICATION = [
  {
    value: "05",
    label: "Cédula",
  },
  {
    value: "04",
    label: "Ruc",
  },
  {
    value: "06",
    label: "Pasaporte",
  },
];

type Props = {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
};

export const SectionClient = ({ expanded, setExpanded }: Props) => {
  const inputRef = useRef<HTMLInputElement>();

  const { key, resetK } = useResetKey();

  const form = useFormContext();
  const values = form.watch();

  const blockedSection = () => {
    return !values?.company;
  };
  useEffect(() => {
    if (values?.hasInvoice === "false") {
      form.reset({ ...values, invoice: undefined });

      // form.reset();
    }
  }, [values?.hasInvoice]);

  const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        form.reset({ ...form.getValues(), invoice: reader.result });
        resetK();
      };
    }
  };

  return (
    <Accordion expanded={expanded} disabled={blockedSection()}>
      <AccordionSummary
        expandIcon={<Iconify icon="akar-icons:chevron-down" />}
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant="subtitle1">Cliente</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container marginRight={0} spacing={1}>
          <Grid item xs={12} md={4}>
            <ClientInformation />
          </Grid>
          <Grid item xs={12} md={4}>
            <ContactInformation />
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" color="primary" mb={2}>
                  Datos de facturación
                </Typography>
                <Stack>
                  <Stack direction="row" alignItems="center" paddingBottom={2}>
                    <Typography variant="subtitle1">
                      ¿Requiere factura?
                    </Typography>
                    <Stack
                      direction="row"
                      flex={1}
                      justifyContent="space-around"
                    >
                      <RHFRadioGroup
                        defaultValue="false"
                        name="hasInvoice"
                        options={[
                          { label: "Sí", value: true },
                          { label: "No", value: false },
                        ]}
                      />
                    </Stack>
                  </Stack>
                  <TextField
                    key={key}
                    inputRef={inputRef}
                    type="file"
                    sx={{ display: "none" }}
                    inputProps={{ accept: ".pdf" }}
                    onChange={handleFileSelected}
                  />
                  <Button
                    disabled={values?.hasInvoice !== "true"}
                    variant="contained"
                    startIcon={<Iconify icon={"ant-design:file-add-filled"} />}
                    onClick={() => {
                      inputRef?.current?.click();
                    }}
                  >
                    Agregar factura
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

type PropsSection = {
  editable?: boolean;
};

export const ClientInformation = ({ editable = true }: PropsSection) => {
  const form = useFormContext();
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" color="primary" mb={2}>
          Datos del cliente
        </Typography>
        <Stack spacing={2}>
          <Stack direction="row" flex={1} spacing={1}>
            <RHFSelect
              name="client.type_identification"
              label="Tipo de identificación"
              inputProps={{ readOnly: !editable }}
              onChange={() => {
                form.setValue("client.identification", "");
              }}
            >
              {TYPE_IDENTIFICATION.map((t) => (
                <MenuItem key={t.value} value={t.value}>
                  {t.label}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFTextField
              label="Número de identificación"
              name="client.identification"
              inputProps={{ readOnly: !editable }}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <RHFTextField
              label="Nombre"
              name="client.name"
              inputProps={{
                readOnly: !editable,
                style: { textTransform: "uppercase" },
              }}
            />
            <RHFTextField
              label="Apellidos"
              name="client.last_name"
              inputProps={{
                readOnly: !editable,
                style: { textTransform: "uppercase" },
              }}
            />
          </Stack>
          <RHFTextField
            label="Empresa"
            name="company.name"
            inputProps={{ readOnly: true }}
          />
          <Stack direction="row" spacing={1}>
            {!editable ? (
              <RHFTextField
                sx={{ width: 160 }}
                label="Código de pais"
                name="client.client_contact_information.country_code"
                inputProps={{
                  readOnly: !editable,
                }}
              />
            ) : (
              <RHFAutocompleteCountryCode
                name="client.client_contact_information.country_code"
                editable={editable}
                sx={{ width: 200 }}
              />
            )}
            <RHFTextField
              label="Numero de celular"
              name="client.client_contact_information.mobile"
              inputProps={{ readOnly: !editable }}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            {!editable ? (
              <RHFTextField
                sx={{ width: 160 }}
                label="Código de pais"
                name="client.client_contact_information.country_code"
                inputProps={{
                  readOnly: !editable,
                }}
              />
            ) : (
              <RHFAutocompleteCountryCode
                name="client.client_contact_information.country_code"
                editable={editable}
                sx={{ width: 200 }}
              />
            )}
            <RHFTextField
              label="Numero de teléfono"
              name="client.client_contact_information.phone"
              inputProps={{ readOnly: !editable }}
            />
          </Stack>
          <RHFTextField
            label="Correo electrónico"
            name="client.client_contact_information.email"
            inputProps={{ readOnly: !editable }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export const ContactInformation = ({ editable = true }: PropsSection) => {
  const [state, setState] = useState<string[]>([]);
  const [coverages, setCoverages] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const form = useFormContext();
  const city = form.watch("client.client_address_information.city");
  const country = form.watch("client.client_address_information.country");
  const _state = form.watch("client.client_address_information.state");

  const defineTypeCoverage = () => {
    const coverage = coverages.find((c) => c.city.toUpperCase() === city);
    console.log(coverage);

    const values = form.getValues();
    form.reset({
      ...values,
      order_information: {
        ...values.order_information,
        type_coverage: coverage?.type_coverage,
      },
    });
  };

  useEffect(() => {
    if (city && editable) {
      setTimeout(() => {
        defineTypeCoverage();
      }, 500);
    }
  }, [city, coverages]);

  useEffect(() => {
    console.log("country=> ", country);
    if (country) {
      const states = countriesStates.find(
        (c) => c.country.toUpperCase() === country.toUpperCase()
      );
      console.log(states);

      setState(states?.states ?? []);
    }
  }, [country]);

  useEffect(() => {
    if (_state) {
      handleChangeState(_state);
    }
  }, [_state]);

  const handleChangeState = async (state: string) => {
    try {
      setLoading(true);
      const resp = await axios.get<any[]>(
        `${COVERAGE_PATH}/coverage-by-state`,
        {
          params: { state },
        }
      );
      const coveragesSort = resp.data.sort((a, b) =>
        a.city > b.city ? 1 : a.city < b.city ? -1 : 0
      );
      setCoverages(coveragesSort);
      // defineTypeCoverage(coveragesSort);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" color="primary" mb={2}>
          Datos direccion de envio
        </Typography>
        <Stack spacing={2}>
          <RHFTextField
            label="Calle principal"
            name="client.client_address_information.address1"
            inputProps={{
              readOnly: !editable,
              style: { textTransform: "uppercase" },
            }}
          />
          <Stack direction="row" flex={1} spacing={1}>
            <RHFTextField
              sx={{ flex: 1 }}
              label="Numeracion"
              name="client.client_address_information.house_number"
              inputProps={{
                readOnly: !editable,
                style: { textTransform: "uppercase" },
              }}
            />
            <RHFTextField
              sx={{ flex: { xs: 1, sm: 2 } }}
              label="Calle secundaria"
              name="client.client_address_information.address2"
              inputProps={{
                readOnly: !editable,
                style: { textTransform: "uppercase" },
              }}
            />
          </Stack>
          <RHFTextField
            sx={{ flex: 1 }}
            label="Referencia"
            name="client.client_address_information.reference"
            inputProps={{
              readOnly: !editable,
              style: { textTransform: "uppercase" },
            }}
          />
          <RHFSelect
            name="client.client_address_information.country"
            fullWidth
            label="Pais"
            inputProps={{ readOnly: !editable }}
            sx={{ flex: 1 }}
            // onChange={(e) => {
            // 	// const states = countriesStates.find(
            // 	// 	(c) => c.country.toUpperCase() === e.target.value.toUpperCase()
            // 	// );
            // 	// console.log(states);
            // 	// setState(states?.states ?? []);
            // }}
          >
            {countriesStates.map((c) => (
              <MenuItem value={c.country.toUpperCase()}>
                {c.country.toUpperCase()}
              </MenuItem>
            ))}
          </RHFSelect>
          {!editable ? (
            <RHFTextField
              sx={{ flex: 1 }}
              label="Estado/Provincia"
              name="client.client_address_information.state"
              inputProps={{
                readOnly: !editable,
                style: { textTransform: "uppercase" },
              }}
            />
          ) : (
            <RHFSelect
              label="Estado/Provincia"
              name="client.client_address_information.state"
              inputProps={{
                readOnly: !editable,
                style: { textTransform: "uppercase" },
              }}
            >
              {state.map((s) => (
                <MenuItem value={s.toUpperCase()}>{s.toUpperCase()}</MenuItem>
              ))}
            </RHFSelect>
          )}
          {!editable ? (
            <RHFTextField
              sx={{ flex: 1 }}
              label="Cobertura"
              name="client.client_address_information.city"
              inputProps={{
                readOnly: !editable,
                style: { textTransform: "uppercase" },
              }}
            />
          ) : (
            <RHFSelect
              sx={{ flex: 1 }}
              label="Cobertura"
              name="client.client_address_information.city"
              inputProps={{ readOnly: !editable }}
              InputProps={{
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                  </React.Fragment>
                ),
              }}
            >
              {coverages.map((c) => (
                <MenuItem value={c.city}>{c.city}</MenuItem>
              ))}
            </RHFSelect>
          )}
          {!editable ? (
            <RHFTextField
              sx={{ flex: 1 }}
              label="Sector"
              name="client.client_address_information.deliveryZone"
              inputProps={{
                readOnly: !editable,
                style: { textTransform: "uppercase" },
              }}
            />
          ) : null}
          {!editable && (
            <Stack direction="row" alignItems="center">
              <Typography variant="subtitle1">¿Requiere factura?</Typography>
              <Stack direction="row" flex={1} justifyContent="space-around">
                <RHFRadioGroup
                  name="hasInvoice"
                  {...(!editable ? { disabled: true } : {})}
                  options={[
                    { label: "Sí", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
