import { AlertColor } from "@mui/material";
import { Category } from "./category";
import { Company } from "./company";
import { PageInfo } from "./order-filter-response";
import { AutocompleteItems } from "././autocompleteItems";

export type OrderState = {
  categories: Category;
  orders: Order[];
  dialogItems: DialogType<Item[]>;
  dialogOrderDetail: DialogType<Order | undefined>;
  dialogChangeStatus: DialogType<Order[] | undefined>;
  dialogNewOrder: DialogType<any>;
  dialogComment: DialogType<Order>;
  dialogViewInvoice: DialogType<string>;
  removeSelected: boolean;
  noData: boolean;
  loading: boolean;
  error: AlertState;
  pageInfo: PageInfo;
  loadingData: boolean;
  autocomplete: AutocompleteItems[];
  params: object;
  changeCourrierDialog: DialogType<Order[] | undefined>;
};

export type Order = {
  hub: Hub;
  uuid: string;
  items: Item[];
  client: Client;
  status: string;
  invoice?: string;
  company?: Company;
  invoice_url?: string;
  external_code?: string;
  line_services?: Item[];
  courrier_info: CourrierInfo;
  order_information: OrderInformation;
  payment_information: PaymentInformation;
  services_pay: ServicesPay;
  comments?: Comment[];
  hasInvoice?: boolean;
};

export type Comment = {
  comment: string;
  date: string;
  type: CommentType;
};

export type ServicesPay = {
  company_payment: "PENDING" | "INVOICED" | "PAID";
  courrier_payment: "PENDING" | "INVOICED" | "PAID";
};

export type AlertState = {
  open: boolean;
  message: string;
  type: AlertColor;
};

export interface Hub {
  city: string;
  city_code: string;
  code: string;
  address: string;
  name: string;
  phone: string;
}

export type DialogType<T> = {
  open: boolean;
  data?: T;
};

/* tslint:disable:all */

export type Client = {
  fullname: string;
  identification: string;
  last_name: string;
  name: string;
  type_identification: "04" | "05" | "06" | string;
  client_address_information: ClientAddressInformation;
  client_contact_information: ClientContactInformation;
};

export type ClientAddressInformation = {
  reference: string;
  country: string;
  address2: string;
  city: string;
  address1: string;
  house_number: string;
  state: string;
  postal_code: string;
};

export interface ClientContactInformation {
  email: string;
  country_code: string;
  mobile: string;
  phone: string;
}

export type PaymentInformation = {
  amount: number;
  payment_method: "1" | "2" | "3";
  status: string;
  unit: string;
  cod: number;
};

export interface OrderInformation {
  type_order: 1 | 2 | 3;
  type_coverage?: string;
  cod?: number;
  date: string;
  type?: string;
}
export interface Item {
  sku: string;
  quantity: number;
  upc: string;
  description: LineServicesEnum | string;
  weight: number;
  volume?: number;
  price: number;
  total: number;
  external_sku?: string;
}
export interface CourrierInfo {
  name?: string;
  code?: string;
  cityCode?: string;
  [k: string]: unknown;
}

export enum LineServicesEnum {
  SHIPMENT_ORDER_SERVICE = "SHIPMENT ORDER",
  COD_SERVICE = "COD SERVICE",
  INSURANCE_SERVICE = "INSURANCE SERVICE",
  INVOICE_SERVICE = "INVOICE SERVICE",
}

export enum CommentType {
  NOVELTY = "NOVELTY",
  IMAGE = "IMAGE",
  COMMENT = "COMMENT",
}
