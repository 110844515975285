// @mui
import { Typography, Stack, StackProps } from "@mui/material";

// ----------------------------------------------------------------------

interface Props extends StackProps {
  dense?: boolean;
  actions?: React.ReactNode;
  rowCount: number;
  numSelected: number;
}

export default function TableSelectedActions({
  dense,
  actions,
  rowCount,
  numSelected,
  sx,
  ...other
}: Props) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        px: 2,
        top: 0,
        left: 8,
        right: 8,
        zIndex: 9,
        height: 58,
        borderRadius: 1,
        // position: 'absolute',
        bgcolor: "primary.lighter",
        ...(dense && {
          pl: 1,
          height: 38,
        }),
        ...sx,
      }}
      {...other}
    >
      <Typography
        variant="subtitle1"
        sx={{
          ml: 2,
          flexGrow: 1,
          color: "primary.main",
          ...(dense && {
            ml: 3,
          }),
        }}
      >
        {numSelected} seleccionado{numSelected > 1 ? "s" : ""}
      </Typography>

      {actions && actions}
    </Stack>
  );
}
