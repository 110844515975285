import {
  Card,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import { OrderState } from "../../../@types/order";
import Scrollbar from "../../../components/Scrollbar";
import { StoreState } from "../../../redux/rootReducer";
import { closeDialogItems } from "../../../redux/slices/orders";
import { useDispatch } from "../../../redux/store";

export default function DialogItems() {
  const dispatch = useDispatch();
  const { dialogItems } = useSelector<StoreState, OrderState>(
    (state) => state.orders
  );

  const onClose = () => {
    dispatch(closeDialogItems());
  };

  return (
    <Dialog {...dialogItems} onClose={onClose} fullWidth maxWidth="md">
      <Card sx={{ p: 1 }}>
        <Scrollbar sx={{ height: "auto" }}>
          <TableContainer sx={{ minWidth: 800, position: "relative" }}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell width={100}>Producto</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>UPC</TableCell>
                  <TableCell align="center">Cantidad</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dialogItems.data?.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ width: "50%" }}>
                      {item.description}
                    </TableCell>
                    <TableCell sx={{ width: "50%" }}>{item.sku}</TableCell>
                    <TableCell sx={{ width: 0 }}>{item.upc}</TableCell>
                    <TableCell align="center" sx={{ width: 0 }}>
                      {item.quantity}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Dialog>
  );
}
