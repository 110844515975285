import { Button, Stack, useTheme } from "@mui/material";
import { DateRangePicker } from "@roy2022/materialui-daterange-picker-updated";
import moment from "moment";
import { useState } from "react";
import { BehaviorSubject } from "rxjs";
import Iconify from "../../../components/Iconify";
import { State } from "./OrderFilter";

type Props = {
  subject: BehaviorSubject<string | object> | undefined;
  state: State;
  parameter: {};
  setParameter: (parameter: {}) => void;
};

export default function DatePickerWrapper(props: Props) {
  const theme = useTheme();
  const { subject, state } = props;
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Stack
      flex={1}
      border={`1px solid ${theme.palette.primary.main}`}
      borderRadius={1}
    >
      <Button
        variant="text"
        startIcon={<Iconify icon={"akar-icons:calendar"} />}
        onClick={() => setOpen(true)}
        sx={{
          height: "100%",
          justifyContent: "center",
          textTransform: "none",
        }}
      >
        {state.key === "order_information.date"
          ? state.value
          : "Seleccione una fecha"}
      </Button>
      <Stack position="absolute">
        <DateRangePicker
          wrapperClassName="datePicker"
          open={open}
          toggle={() => setOpen(false)}
          onChange={(dateRange) => {
            if (dateRange.startDate) {
              const dateStart = moment(dateRange.startDate).format(
                "MM/DD/YYYY"
              );
              const dateEnd = moment(dateRange.endDate).format("MM/DD/YYYY");
              const dateFilter = `${dateStart}-${dateEnd}`;
              subject?.next({
                value: dateFilter,
                key: "order_information.date",
                categoryName: "Categorías",
                widget: "text",
              });
              props.setParameter({
                ...props.parameter,
                "order_information.date": {
                  value: dateFilter,
                  label: dateFilter,
                },
              });
              setOpen(false);
            }
          }}
          definedRanges={[]}
        />
      </Stack>
    </Stack>
  );
}
