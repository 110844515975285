import { Box, Dialog, Stack, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { DialogType } from "../../../@types/order";
import Page from "../../../components/Page";
import { StoreState } from "../../../redux/rootReducer";
import { closeDialogComment } from "../../../redux/slices/orders";
import { useDispatch } from "../../../redux/store";

export const DialogComment = () => {
  const dialog = useSelector<StoreState, DialogType<any>>(
    (state) => state.orders.dialogComment
  );

  const dispatch = useDispatch();

  const comments = dialog.data?.comments ?? [];

  const handleCloseDialog = () => {
    dispatch(closeDialogComment());
  };

  const theme = useTheme();

  return (
    <Dialog {...dialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
      <Page sx={{ backgroundColor: theme.palette.grey[100] }}>
        <Stack p={3}>
          <Box>
            <Typography variant="h4">Comentario</Typography>
          </Box>

          <Stack paddingY={2} gap={1}>
            {comments.length === 0 && (
              <Typography variant="subtitle2" textAlign="center">
                No se han agregado notas internas
              </Typography>
            )}
            {comments.length > 0 && (
              <Typography variant="subtitle2">
                {comments[comments.length - 1]}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Page>
    </Dialog>
  );
};
