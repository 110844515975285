import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import Iconify from "../../../../components/Iconify";
import { openDialogViewInvoice } from "../../../../redux/slices/orders";
import { useDispatch } from "../../../../redux/store";

export const SectionNewDocuments = () => {
  const form = useFormContext();
  const dispatch = useDispatch();
  const invoice = form.watch("invoice");
  const numberOr = moment().valueOf().toString().substring(5);

  const handleViewInvoice = () => {
    dispatch(openDialogViewInvoice(invoice));
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" color="primary">
            Documentos
          </Typography>
        }
      />
      <CardContent>
        {invoice && (
          <Button
            variant="text"
            startIcon={<Iconify icon="fa6-regular:file-pdf" />}
            onClick={handleViewInvoice}
          >
            <Typography>FACT_OR{numberOr}</Typography>
          </Button>
        )}
      </CardContent>
    </Card>
  );
};
