import {
  Box,
  Card,
  Divider,
  Stack,
  TextField,
  Typography,
  TypographyVariant,
  useTheme,
} from "@mui/material";
import { Order } from "../../../@types/order";
import { PaymentMethod } from "../list/UserTableRow";

type Prop = {
  order?: Order;
};

type Services = "shipping" | "insurance" | "invoice" | "pickandpack";

const skuMap: Record<Services, string[]> = {
  shipping: ["140", "141", "142", "143", "144", "145"],
  insurance: ["147"],
  invoice: ["148"],
  pickandpack: ["149"],
};

export const SectionLinesService = ({ order }: Prop) => {
  const total = order?.line_services?.reduce((p, c) => p + c.total, 0) ?? 0;

  const theme = useTheme();

  const _finService = (name: Services) => {
    const skus = skuMap[name];
    return order?.line_services?.find((s) => skus.includes(s.sku));
  };

  const costService = (name: Services) => {
    const service = _finService(name);
    return service?.total || 0;
  };

  const hasService = (name: Services) => {
    const service = _finService(name);
    return !!service ? "Sí" : "No";
  };

  const totalProducts = () => {
    return order?.items.reduce((p, c) => p + c.total, 0) ?? 0;
  };

  return (
    <Box
      sx={{
        width: { md: "40%", sm: "100%" },
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Card>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ background: theme.palette.primary.main }}
          p={1}
        >
          <Typography variant="h5" color={theme.palette.common.white}>
            Subtotal de productos
          </Typography>
          <Typography variant="subtitle1" color={theme.palette.common.white}>
            {order?.items.reduce((p, c) => p + c.total, 0).toFixed(2)}
          </Typography>
        </Stack>
      </Card>
      <Card sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Stack p={2}>
          <Typography variant="h6" color="primary" mb={2}>
            Valor por servicios
          </Typography>
          <Stack gap={2}>
            <ServiceItem
              serviceName="Envío"
              total={costService("shipping")}
              value1={`${order?.courrier_info?.name}`}
              value2="Normal"
            />
            <ServiceItem
              serviceName="Seguro"
              total={costService("insurance")}
              value1={hasService("insurance")}
              value2="1%"
            />
            <ServiceItem
              serviceName="Impresión de factura"
              total={costService("invoice")}
              value1={hasService("invoice")}
            />
            <ServiceItem
              serviceName="Recogida y embalaje"
              total={costService("pickandpack")}
              value1={hasService("pickandpack")}
            />
            <ServiceItem
              serviceName="Método de pago"
              total={totalProducts()}
              value1={
                PaymentMethod[order?.payment_information.payment_method ?? -1]
              }
              value2={`${totalProducts() > 100 ? ">" : "<"}100`}
            />
            <Divider />
            <Stack gap={1} paddingY={1}>
              <TotalItem total={total} description="Sutbotal servicios" />
              <TotalItem total={total * 0.12} description="Impuestos" />
              <TotalItem
                total={total * 0.12 + total}
                description="Valor total"
              />
            </Stack>
            <Divider />
            <TotalItem
              total={order?.order_information.cod ?? 0}
              description="Valor a recaudar"
              variant="h4"
            />
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export const TotalItem = ({
  total,
  description,
  variant = "subtitle1",
}: {
  total: number;
  description: string;
  variant?: TypographyVariant;
}) => {
  return (
    <Stack direction="row">
      <Stack width="65%">
        <Typography variant={variant}>{description}</Typography>
      </Stack>
      <Stack width="35%" alignItems="flex-end">
        <Typography>{total.toFixed(2)}</Typography>
      </Stack>
    </Stack>
  );
};

type Props = {
  serviceName: string;
  total: number;
  value1: string;
  value2?: string;
};

export const ServiceItem = ({ serviceName, total, value1, value2 }: Props) => {
  return (
    <Stack>
      <Typography>{serviceName}</Typography>
      <Stack direction="row" gap={1}>
        <Stack direction="row" gap={1} width="60%">
          <TextField
            sx={{ flex: 1 }}
            inputProps={{ readOnly: true }}
            value={value1}
            disabled
          />
          {value2 && (
            <TextField
              sx={{ flex: 1 }}
              inputProps={{ readOnly: true }}
              value={value2}
              disabled
            />
          )}
        </Stack>
        <Stack alignItems="flex-end" width="40%" justifyContent="center">
          <Typography>{`${total.toFixed(2)}`}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
