import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { Comment, CommentType, Order } from "../../../@types/order";
import Iconify from "../../../components/Iconify";
import Label from "../../../components/Label";
import Page from "../../../components/Page";
import { addComment } from "../../../redux/slices/orders";
import { useDispatch } from "../../../redux/store";

type Props = {
  order?: Order;
};

export const SectionComments = ({ order }: Props) => {
  const [valueComment, setValueComment] = useState<string>("");

  const [comments, setComments] = useState<Comment[]>(order?.comments ?? []);
  const [comment, setComment] = useState<Comment>();
  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleAddComment = () => {
    if (!valueComment) {
      return;
    }
    const newComment: Comment = {
      comment: valueComment,
      type: CommentType.COMMENT,
      date: moment().format("YYYY-MM-DDTHH:mm:ss"),
    };
    dispatch(addComment(newComment, `${order?.uuid}`)).then(() => {
      setComments([...comments, newComment]);
      setValueComment("");
    });
  };

  const sortComments = () => {
    const sort = [...comments]
      .sort((a, b) => {
        let aDate = parseDate(a).valueOf();
        let bDate = parseDate(b).valueOf();
        return bDate - aDate;
      })
      .map((comment) => ({
        ...comment,
        date: parseDate(comment).format("YYYY-MM-DDTHH:mm:ss"),
      }));
    return sort;
  };

  const parseDate = (comment: Comment) => {
    return comment.type === CommentType.IMAGE
      ? moment(comment.date, "DD/MM/YYYY HH:mm:ss A")
      : moment(comment.date);
  };

  const openViewImageModal = (comment: Comment) => {
    setComment(comment);
    setOpen(true);
  };

  return (
    <Stack flex={1}>
      <Card>
        <CardHeader
          title={
            <Typography variant="h6" color="primary">
              Comentarios
            </Typography>
          }
        />
        <CardContent>
          <Stack paddingY={2} gap={1}>
            {comments.length === 0 && (
              <Typography variant="subtitle1" textAlign="center">
                No se han agregado notas internas
              </Typography>
            )}
            {sortComments().map((c) => (
              <Stack direction="row" alignItems="center" gap={2}>
                <Label
                  variant={"filled"}
                  color="primary"
                  sx={{ textTransform: "capitalize" }}
                >
                  <Typography variant="caption">
                    {c.date.trim().replace("T", " ")}
                  </Typography>
                </Label>
                {c.type === CommentType.IMAGE ? (
                  <IconButton
                    color="primary"
                    onClick={() => openViewImageModal(c)}
                  >
                    <Iconify icon="mdi:file-image-outline" />
                  </IconButton>
                ) : (
                  <Typography flex={1} sx={{ textTransform: "uppercase" }}>
                    {c.comment}
                  </Typography>
                )}
              </Stack>
            ))}
          </Stack>

          <Stack paddingY={2}>
            <TextField
              value={valueComment}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  handleAddComment();
                  e.preventDefault();
                }
              }}
              onChange={(e) => setValueComment(e.target.value)}
              label="Ingrese notas referente a la orden"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton color="primary" onClick={handleAddComment}>
                      <Iconify icon="fluent:send-16-filled" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </CardContent>
      </Card>
      <ViewImageDialog
        open={open}
        comment={comment}
        onClose={() => setOpen(false)}
      />
    </Stack>
  );
};

type ViewImageProps = {
  open: boolean;
  onClose: VoidFunction;
  comment?: Comment;
};

const ViewImageDialog = (dialog: ViewImageProps) => {
  const theme = useTheme();

  const { comment } = dialog;

  const buildUrlImage = () => {
    if (comment) {
      const cArray = comment.comment.split("/");
      const env = cArray[0];
      cArray.shift();
      return `https://${env}.s3.amazonaws.com/${cArray.join("/")}`;
    }
    return "";
  };

  return (
    <Dialog {...dialog} fullWidth maxWidth="md">
      <Page sx={{ backgroundColor: theme.palette.grey[100] }}>
        <Stack
          sx={{ backgroundColor: theme.palette.primary.main, p: 2 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4" color={theme.palette.common.white}>
            Visor de imágenes
          </Typography>
          <IconButton onClick={dialog.onClose}>
            <Iconify
              icon="ant-design:close-circle-filled"
              sx={{ color: theme.palette.common.white }}
            />
          </IconButton>
        </Stack>
        <Stack minHeight={400} alignItems="center">
          <img src={buildUrlImage()} />
        </Stack>
      </Page>
    </Dialog>
  );
};
