import { Avatar, Box, Stack, Typography } from "@mui/material";
import { Item, Order } from "../../../../@types/order";

export default function CourrierInfo(props: { order: Order | undefined }) {
  const { order } = props;

  const defineTotalService = (lineService: Item[]) => {
    const filter = lineService.filter((item) =>
      item.description.includes("SHIPMENT ORDER")
    )[0];
    return filter?.total;
  };

  return (
    <Stack direction="row" width="max-content" spacing={2}>
      <Avatar>{(order?.courrier_info?.name ?? "")[0]}</Avatar>
      <Stack>
        <Box>
          <Typography variant="body2" noWrap>
            ENVIO{" "}
            {(order?.order_information?.type_coverage ?? "").toUpperCase()}
          </Typography>
        </Box>
        <Typography variant="caption" noWrap>
          $ {defineTotalService(order?.line_services ?? [])}
        </Typography>
      </Stack>
    </Stack>
  );
}
