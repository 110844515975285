import {
  Stack,
  InputAdornment,
  TextField,
  MenuItem,
  Button,
  IconButton,
  Chip,
  Autocomplete,
  Menu,
} from "@mui/material";
import { debounceTime, map } from "rxjs/operators";
import { useFirestore } from "reactfire";
import { useSelector } from "react-redux";
import { BehaviorSubject } from "rxjs";
import { useEffect, useRef, useState } from "react";
import { MenuItemData, NestedDropdown } from "mui-nested-menu";

import Iconify from "../../../components/Iconify";
import { OrderState } from "../../../@types/order";
import { useDispatch } from "../../../redux/store";
import DatePickerWrapper from "./DatePickerWrapper";
import { StoreState } from "../../../redux/rootReducer";
import { NumberPhoneFilter } from "./NumberPhoneFilter";
import useImportFile from "../../../hooks/useImportFile";
import { ChildrenCategory } from "../../../@types/category";
import {
  openDialogNewOrder,
  searchOrders,
  setLoadingData,
  cleanOrder,
  getCourriers,
  getCompany,
  cleanAutocomplete,
  validateReporstPrams,
} from "../../../redux/slices/orders";
import { useQuery } from "@tanstack/react-query";
import { countriesStates } from "../../../_data/CountryStates";

type WidgetFilter = "text" | "select" | "mobile" | "autocomplete";

export type State = {
  key: string;
  value?: string;
  categoryName: string;
  widget: WidgetFilter;
};

const initState: State = {
  key: "uuid",
  categoryName: "Categorías",
  widget: "text",
};

const widgetMap: Record<string, WidgetFilter> = {
  "courrier_info.name": "autocomplete",
  "client.client_contact_information.full_mobile": "mobile",
  uuid: "text",
  "client.fullname": "text",
  "client.identification": "text",
  external_code: "text",
  "company.name": "autocomplete",
  status: "select",
  "hub.code": "select",
  "client.client_address_information.country": "autocomplete",
  "client.client_address_information.city": "text",
  "order_information.type_order": "select",
  "client.client_address_information.state": "autocomplete",
  "payment_information.payment_method": "select",
};

const country = countriesStates;

type Props = {
  limit: number;
  page: number;
  action: number | undefined;
};

export default function OrderFilter({ limit, page, action }: Props) {
  //menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //nueva logica
  let [parameter, setParameter] = useState({});

  const searchSeveral = () => {
    parameter["uuid"]
      ? (parameter["uuid"] = parameter["uuid"].value.replace(/ /g, ","))
      : null;
    return dispatch(searchOrders(parameter));
  };
  //delete chip
  const handleDelete = (id: string) => {
    delete parameter[id];
    setParameter({ ...parameter, limit: 25 });
    setValueSearch(id + ":" + parameter[id]);
  };

  //-----------------------------------------------------------------------------

  const [categoriesState, setCategoriesState] = useState<MenuItemData>({
    label: initState.categoryName,
    items: [],
  });

  const dispatch = useDispatch();
  const firestore = useFirestore();

  const inputRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<State>(initState);

  const [valueSearch, setValueSearch] = useState<string>();
  const [options, setOptions] = useState<ChildrenCategory[]>([]);
  const { categories, pageInfo, autocomplete } = useSelector<
    StoreState,
    OrderState
  >((state) => state.orders);

  const [method, setMethod] = useState("");

  const { onFileSelected, key } = useImportFile({ firestore, method: method });

  const [subject, setSubject] = useState<BehaviorSubject<string | object>>();

  const handleMenuItemClick = (method: string) => {
    setMethod(method);
    console.log("method: ", method);
    inputRef?.current?.click();
    handleClose();
  };

  useQuery({
    queryKey: ["filters-custom", { key: state.key }],
    queryFn: ({ queryKey }) => {
      const q = queryKey[1] as any;
      switch (q.key) {
        case "courrier_info.name":
          return dispatch(getCourriers());
        case "company.name":
          return dispatch(getCompany());
        default:
          return;
      }
    },
    enabled: ["courrier_info.name", "company.name"].includes(state.key),
    refetchOnWindowFocus: false,
  });
  useQuery({
    queryKey: [
      "order-filter",
      /* { limit, value: valueSearch, page } */
      { ...parameter, limit, page },
    ],
    queryFn: ({ queryKey }) => {
      cleanOrder();
      setLoadingData(true);
      const q = queryKey[1] as any;
      parameter = { ...parameter, limit: q.limit };
      /*const param = { limit: q.limit };
      if (state.key && q.value) {
        param[state.key] = `${q.value}`;
      }*/
      if (action === 1) {
        parameter["after"] = pageInfo.endCursor;
      }
      if (action === -1) {
        parameter["before"] = pageInfo.startCursor;
      }
      return searchSeveral();
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!subject || subject.closed) {
      const sub = new BehaviorSubject<string | object>("");
      setSubject(sub);
      return;
    }
    const subject$ = subject
      .pipe(
        map((search: string | object) => {
          let searchReplace = "";
          if (typeof search === "string") {
            searchReplace =
              state.key === "uuid" ? search.replace(/ /g, ",") : search;
          } else {
            setState({ ...state, ...search });
            setParameter({
              ...parameter,
              [search["key"]]: {
                value: search["value"],
                label: search["value"],
              },
            });

            searchReplace = search["value"];
          }
          return searchReplace;
        }),
        debounceTime(500),

        map((search) => {
          setValueSearch(search);
          const param = {};
          if (state.key && search) {
            param[state.key] = `${search}`;
          }
          return param;
        })
      )
      .subscribe({
        error: (err) => {
          console.log(err);
        },
      });
    return () => {
      subject$?.unsubscribe();
      subject.unsubscribe();
    };
  }, [subject, state.categoryName]);

  useEffect(() => {
    if (state.categoryName) {
      setCategoriesState({
        label: state.categoryName,
        items: categoriesState.items,
      });
    }
  }, [state.categoryName]);

  useEffect(() => {
    if (categories.categories.length > 0) {
      //todo: refactor when maked next history
      const items = categories.categories.map((c) => {
        const items = (c.children ?? []).map((children) => {
          return {
            label: children.label,
            callback: () => {
              cleanAutocomplete();
              const widget = widgetMap[children.id] ?? state.widget;
              if ((children.children ?? []).length > 0) {
                setOptions(children.children);
              }
              setState({
                ...state,
                value: "",
                categoryName: children.label,
                key: children.id,
                widget: widget,
              });
            },
          };
        });
        return {
          ...c,
          callback: () => {
            if (!c.is_submenu) {
              const widget = widgetMap[c.id] ?? state.widget;
              setState({
                ...state,
                widget: widget,
                value: "",
                categoryName: c.label,
                key: c.id,
              });
              setOptions(c.children);
              return;
            }
            setState({ ...state, categoryName: c.label, key: c.id });
            // setFilterCategory(c.label);
          },
          items: c.is_submenu ? items : [],
        };
      });
      setCategoriesState({ label: state.categoryName, items });
    }
  }, [categories, state.value]);

  const handleNewOrder = () => {
    dispatch(openDialogNewOrder());
  };

  const autocompleteOptions = (key: string) => {
    switch (key) {
      case "company.name":
      case "courrier_info.name":
        return autocomplete.map((option) => option.name);
      case "client.client_address_information.country":
        return country.map((option) => option.country.toLocaleUpperCase());
      default:
        return (
          country
            .find(
              (option) =>
                option.country.toLocaleUpperCase() ===
                parameter["client.client_address_information.country"]?.value
            )
            ?.states.map((option) => option.toLocaleUpperCase()) ?? []
        );
    }
  };

  const massiveReport = () => {
    validateReporstPrams();
  };

  return (
    <Stack direction="column">
      <Stack
        spacing={3}
        direction={{ xs: "column-reverse", md: "row" }}
        height={{ md: 91 }}
        sx={{ py: 2.5, px: 3 }}
        zIndex={10}
      >
        <Stack direction={{ sm: "row", xs: "column" }} flex="1" spacing={3}>
          <Stack sx={{ width: { sm: "30%" }, maxWidth: "210px" }}>
            <DatePickerWrapper
              subject={subject}
              state={state}
              setParameter={setParameter}
              parameter={parameter}
            />
          </Stack>
          <NestedDropdown
            menuItemsData={categoriesState}
            MenuProps={{ sx: { height: "100%" }, elevation: 3 }}
            ButtonProps={{
              sx: {
                height: "100%",
                minWidth: { sm: "190px", xs: "100%" },
                textTransform: "initial",
              },
              variant: "outlined",
            }}
          />
          <Stack direction="row" flex={1}>
            {state.widget === "text" && (
              <TextField
                fullWidth
                sx={{ height: "100%" }}
                value={
                  state.key === "order_information.date" ? "" : state.value
                }
                onChange={(event) => {
                  subject?.next(event.target.value);
                  setState({ ...state, value: event.target.value });
                  setParameter({
                    ...parameter,
                    [state.key]: {
                      value: event.target.value.toLocaleUpperCase(),
                      label: event.target.value.toLocaleUpperCase(),
                    },
                  });
                  if (!event.target.value) {
                    handleDelete(state.key);
                    return;
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon={"eva:search-fill"}
                        sx={{ color: "text.disabled", width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  ),
                  sx: { height: "100%" },
                }}
              />
            )}
            {state.widget === "select" && (
              <TextField
                fullWidth
                value={
                  state.key === "order_information.date" ? "" : state.value
                }
                select
                sx={{ width: { md: "100%", xs: "100%" } }}
                onChange={(event) => {
                  subject?.next(event.target.value);
                  setState({ ...state, value: event.target.value });
                  const label = options.find(
                    (option) => option.id === event.target.value
                  );
                  setParameter({
                    ...parameter,
                    [state.key]: {
                      value: event.target.value,
                      label: label?.label,
                    },
                  });
                  console.log(parameter);
                  console.log("Sub ", subject);
                }}
                label="Selecciona una opcion"
                InputProps={{
                  sx: { height: "100%" },
                }}
              >
                {options.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {state.widget === "autocomplete" && (
              <Autocomplete
                freeSolo
                disableClearable
                id="autocomplete"
                value={
                  state.key === "order_information.date" ? "" : state.value
                }
                options={autocompleteOptions(state.key)}
                noOptionsText="No hay opciones"
                renderInput={(text) => <TextField {...text} label="Buscar" />}
                onChange={(_, value) => {
                  subject?.next(value ? value : "");
                  setState({ ...state, value: value ? value : "" });
                  setParameter({
                    ...parameter,
                    [state.key]: { value: value, label: value },
                  });
                }}
                sx={{ width: { md: "100%", xs: "100%" } }}
              />
            )}
            {state.widget === "mobile" && (
              <NumberPhoneFilter
                subject={subject}
                params={parameter}
                setParams={setParameter}
              />
            )}
            {valueSearch && (
              <IconButton
                onClick={() => {
                  setState({
                    ...state,
                    value: "",
                    categoryName: "Categorias",
                    key: "uuid",
                    widget: "text",
                  });
                  setValueSearch("");
                  setParameter({});
                }}
              >
                <Iconify icon="eva:close-square-fill" width={40} height={40} />
              </IconButton>
            )}
          </Stack>
        </Stack>

        <Stack direction="row" sx={{ flexShrink: 0 }} spacing={1}>
          <TextField
            key={key}
            inputRef={inputRef}
            type="file"
            sx={{ display: "none" }}
            inputProps={{ accept: ".csv" }}
            onChange={onFileSelected}
          />
          {/* <Button
            variant="contained"
            startIcon={<Iconify icon={"eva:cloud-upload-fill"} />}
            onClick={() => {
              inputRef?.current?.click();
            }}
          >
            Cargar Órdenes
          </Button> */}
          <Button
            variant="contained"
            startIcon={<Iconify icon={"eva:plus-fill"} />}
            onClick={handleNewOrder}
          >
            Nueva orden
          </Button>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Iconify icon={"eva:more-vertical-fill"} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "20ch",
              },
            }}
          >
            <MenuItem onClick={() => handleMenuItemClick("ORDER")}>
              Cargar Órdenes
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("ORDERSTATE")}>
              Cargar Estados
            </MenuItem>
            <MenuItem onClick={massiveReport}>Reporte Global</MenuItem>
          </Menu>
        </Stack>
      </Stack>
      {Object.keys(parameter).length > 0 && (
        <Stack direction="row" spacing={1} paddingBottom={2} flexWrap="wrap">
          {Object.keys(parameter).map((key) =>
            key === "limit" ? null : parameter[key].length == 0 ? null : (
              <Stack
                direction="column"
                sx={{ paddingLeft: 3, paddingBottom: 1 }}
              >
                <Chip
                  label={parameter[key].label}
                  onDelete={() => {
                    handleDelete(key);
                    if (key === "order_information.date") {
                      setState({
                        ...state,
                        value: "Seleccione una fecha",
                      });
                    }
                  }}
                  variant="outlined"
                  color="primary"
                />
              </Stack>
            )
          )}
        </Stack>
      )}
    </Stack>
  );
}
