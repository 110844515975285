import { combineReducers } from "redux";
import { OrderState } from "../@types/order";

import orders from "./slices/orders";
// slices

export type StoreState = {
  orders: OrderState;
};
// -------------------------------------------------------------------

const rootReducer = combineReducers<StoreState>({
  orders,
});

export { rootReducer };
