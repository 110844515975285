export const StatusesMap: Record<StatusesEnum, string> = {
  CREATED: "Creado",
  PENDING: "Pendiente",
  PICKING: "En picking",
  PACKING: "En packing",
  CANCELLED: "Cancelada",
  SHIPPED: "Recolectado",
  READYTOSHIP: "En zona de despacho",
  DELETED: "Eliminada",
  INWAREHOUSE: "En bodega",
  RECOLECTADO: "Recolectado",
  INTRANSIT: "En tránsito",
  INDELIVERYAREA: "Zona de entrega",
  DELIVERED: "Entregado",
  TORETURN: "De regreso",
  RETURNED: "Devuelto",
  MODIFYING: "Modificado",
};

export enum StatusesEnum {
  MODIFYING = "MODIFYING",
  CREATED = "CREATED",
  PENDING = "PENDING",
  PICKING = "PICKING",
  PACKING = "PACKING",
  CANCELLED = "CANCELLED",
  SHIPPED = "SHIPPED",
  READYTOSHIP = "READYTOSHIP",
  DELETED = "DELETED",
  INWAREHOUSE = "INWAREHOUSE",
  RECOLECTADO = "RECOLECTADO",
  INTRANSIT = "INTRANSIT",
  INDELIVERYAREA = "INDELIVERYAREA",
  DELIVERED = "DELIVERED",
  TORETURN = "TORETURN",
  RETURNED = "RETURNED",
}
