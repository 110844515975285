import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { DialogType, Order } from "../../../@types/order";
import { StoreState } from "../../../redux/rootReducer";
import {
  changeStatus,
  closeDialogChangeStatus,
  openLoading,
} from "../../../redux/slices/orders";
import { useDispatch } from "../../../redux/store";

type statusMap = "PICKING" | "PACKING" | "RECOLECTADO";
export type Orders = {
  uuids: string[] | undefined,
  status: string | undefined
}
export const DialogChangeStatus = () => {
  const { open, data } = useSelector<
    StoreState,
    DialogType<Order[] | undefined>
  >((state) => state.orders.dialogChangeStatus);

  const dispatch = useDispatch();

  const [status, setStatus] = useState<statusMap>();

  const handleClose = () => {
    dispatch(closeDialogChangeStatus());
  };

  const onChangeStatus = () => {
    console.log(data)
    console.log(status)
    let statusValidate = "CREATED";
    switch (status) {
      case "PACKING":
        statusValidate = "PICKING";
        break;
      case "RECOLECTADO":
        statusValidate = "PACKING";
    }
    console.log('data: ', data)
    console.log('status ', status)
    const filter = data?.filter((order) => order.status === statusValidate);
    if ((filter?.length ?? 0) > 0) {
      openLoading();
      const uuid = data?.map((or) => or.uuid)
      const order: Orders = { uuids: uuid, status: status }
      dispatch(changeStatus(order))
    };
  }

  const statuses: { label: string; value: statusMap }[] = [
    { label: "PICKING", value: "PICKING" },
    { label: "PACKING", value: "PACKING" },
    { label: "RECOLECTADO", value: "RECOLECTADO" },
  ];

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Cambiar de estado</DialogTitle>
      <DialogContent>
        <Stack>
          <ToggleButtonGroup
            color="primary"
            value={status}
            onChange={(_, value) => setStatus(value)}
            exclusive
            sx={{ justifyContent: "center", gap: 2, marginTop: 2 }}
          >
            {statuses.map((status) => (
              <ToggleButton value={status.value}>{status.label}</ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          disabled={!status}
          onClick={onChangeStatus}
        >
          Actualizar estado
        </Button>
      </DialogActions>
    </Dialog>
  );
};
