import { ChangeEvent, useState } from "react";
import {
  closeLoading,
  openLoading,
  searchOrders,
  setAlert,
  uploadBatchFile,
  uploadOrderStateFile,
} from "../redux/slices/orders";
import { useDispatch } from "../redux/store";
import {
  collection,
  addDoc,
  Firestore,
  onSnapshot,
  doc,
  Unsubscribe,
} from "firebase/firestore";
import useResetKey from "./useResetKey";

export type IPropsFirebaseCustomHook = {
  firestore: Firestore;
  method: string;
};

const action = (snapshot: Unsubscribe) => {
  closeLoading();
  snapshot();
};

const collectionName = `${process.env.STAGE}-usrv-order-orders`;

export default function useImportFile({
  firestore,
  method,
}: IPropsFirebaseCustomHook) {
  const collectionDocuments = collection(firestore, collectionName);

  const { key, resetK } = useResetKey();

  const [documentId, setDocumentId] = useState<string>();

  const dispatch = useDispatch();
  const onFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    openLoading();
    const files = e.target.files;
    if (files) {
      const formData = new FormData();
      const document = await addDoc(collectionDocuments, { status: "PENDING" });
      setDocumentId(document.id);
      formData.append("file", files[0]);
      formData.append("documentId", document.id);
      switch (method) {
        case "ORDER":
          await dispatch(uploadBatchFile(formData));
          break;
        case "ORDERSTATE":
          await dispatch(uploadOrderStateFile(formData));
          break;
      }

      resetK();
      const snapshot = onSnapshot(
        doc(firestore, collectionName, document.id),
        (d) => {
          const status = d.data()?.status;

          switch (status) {
            case "SUCCESS":
              action(snapshot);
              break;
            case "FAILED":
              const message = d.data()?.message;
              setAlert({ open: true, type: "error", message });
              action(snapshot);
            default:
          }
        }
      );
    }
    closeLoading();
    await dispatch(searchOrders({limit:25}))
  };

  return { onFileSelected, key, documentId };
}
