import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Item } from "../../../@types/order";
import Iconify from "../../../components/Iconify";
import Label from "../../../components/Label";
import { openDialogItems } from "../../../redux/slices/orders";
import { useDispatch } from "../../../redux/store";

type Props = {
  items: Item[];
};

export default function ItemCell(props: Props) {
  const { items } = props;
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(openDialogItems(items));
  };

  return (
    <>
      {items ? (
        <Box
          sx={{
            border: "1px dashed black",
            width: "250px",
            p: 1,
            borderRadius: 1,
          }}
        >
          <Stack direction="row" spacing={2}>
            <Stack alignItems="center">
              <Typography variant="body2">Cant</Typography>
              <Typography variant="caption">
                {items.reduce((p, c) => p + c.quantity, 0)}
              </Typography>
            </Stack>
            <Stack sx={{ flex: 1 }} justifyContent="center">
              <Typography variant="body2">{items[0].description}</Typography>
            </Stack>
            <IconButton onClick={openModal}>
              <Iconify icon="ep:d-arrow-right" />
            </IconButton>
          </Stack>
        </Box>
      ) : (
        <Label color="error" sx={{ textTransform: "capitalize" }}>
          Sin productos
        </Label>
      )}
    </>
  );
}
