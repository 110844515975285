import {
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { KeyboardEvent, useState } from "react";
import { RHFTextField } from "../../../../components/hook-form";
import Iconify from "../../../../components/Iconify";
import { useFormContext } from "react-hook-form";
import axios from "../../../../utils/axios";
import { COMPANY_PATH, ORDER_PATH } from "../../../../constants/paths";
import { Company } from "../../../../@types/company";
import {
  closeLoading,
  openLoading,
  setAlert,
} from "../../../../redux/slices/orders";
import { useMutation } from "@tanstack/react-query";
import { Errors } from "../../SnackAlert";
type Props = {
  setExpanded: (expanded: boolean) => void;
};
export const SectionSearch = ({ setExpanded }: Props) => {
  const theme = useTheme();
  const form = useFormContext();
  const [filterCompany, setFilterCompany] = useState<string>("");
  const [clientFind, setClientFind] = useState<string>("");

  const findCompany = async (e: KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    let key = "code";
    try {
      openLoading();
      if (filterCompany.length > 6) {
        key = "identification";
      }
      const response = await axios.get<Company>(`${COMPANY_PATH}/companies`, {
        params: { [key]: filterCompany },
      });

      form.reset({
        ...form.getValues(),
        company: response.data,
      });
    } catch (e) {
    } finally {
      closeLoading();
    }
  };

  const findClientMutation = useMutation({
    mutationFn: (clientId: string) => {
      return axios.get(`${ORDER_PATH}/search/orders`, {
        params: { "client.identification": clientId },
      });
    },
    onSuccess: (resp) => {
      closeLoading();
      const { data } = resp;
      if (data.orders.length === 0) return setAlert(Errors.E008);
      const client = data.orders[0].client;

      delete client.client_address_information.location;
      delete client.client_address_information.deliveryZone;
      console.log(client);

      form.reset({ ...form.getValues(), client });
      setExpanded(true);
    },
    onMutate: () => openLoading(),
    onError: (e) => {
      console.log(e);
      setAlert(Errors.E100);
    },
  });

  const iconBuild = (icon = "bx:search-alt") => (
    <InputAdornment position="start">
      <Iconify icon={icon} color={theme.palette.primary.main} />
    </InputAdornment>
  );

  return (
    <Stack py={6} direction="row" justifyContent="center" gap={6}>
      <Stack direction="row" gap={4}>
        <TextField
          fullWidth
          value={filterCompany}
          onChange={(e) => setFilterCompany(e.target.value)}
          label="Buscar empresa"
          InputProps={{
            startAdornment: iconBuild(),
          }}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              findCompany(e);
              e.preventDefault();
            }
          }}
        />
        <RHFTextField
          label="Empresa"
          name="company.name"
          disabled
          InputProps={{ startAdornment: iconBuild("carbon:location-company") }}
        />
      </Stack>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Stack direction="row" gap={4} alignItems="center">
        <TextField
          label="Buscar cliente"
          value={clientFind}
          onChange={(e) => setClientFind(e.target.value)}
          InputProps={{
            startAdornment: iconBuild(),
          }}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              findClientMutation.mutate(clientFind);
              e.preventDefault();
            }
          }}
        />
        Ó
        <Button variant="text" onClick={() => setExpanded(true)}>
          Crear nuevo cliente
        </Button>
      </Stack>
    </Stack>
  );
};
