// @mui
import { TableRow, TableCell } from "@mui/material";
import { TABLE_HEAD } from "../../constants/OrderHeadTable";
//
import EmptyContent from "../EmptyContent";

// ----------------------------------------------------------------------

type Props = {
  isNotFound: boolean;
};

export default function TableNoData({ isNotFound }: Props) {
  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={TABLE_HEAD.length}>
          <EmptyContent
            title="No existen datos"
            sx={{
              "& span.MuiBox-root": { height: 160 },
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={TABLE_HEAD.length} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}
